import styled from "styled-components"
import device from "utils/media"

export const TooltipWrap = styled.div`
  display: inline-block;
  position: relative;

  /* Absolute positioning */
  .Tooltip-Tip {
    position: absolute;
    border-radius: 4px;
    left: calc(50% - 4px);
    transform: translateX(-50%);
    padding: 6px;
    color: white;
    background: black;
    font-size: 14px;
    font-family: sans-serif;
    line-height: 1;
    z-index: 100;
    white-space: nowrap;
    opacity: 0;

    @media ${device.sm} {
      left: calc(50% - 8px);
    }

    &.active {
      opacity: 1;
      transition: all 1s;
      -webkit-transition: all 1s;
    }

    /* CSS border triangles */
    &::before {
      content: " ";
      left: 50%;
      border: solid transparent;
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-width: 6px;
      margin-left: calc(6px * -1);
    }

    /* Absolute positioning */
    &.top {
      top: calc(30px * -1);
      /* CSS border triangles */
      &::before {
        top: 100%;
        border-top-color: black;
      }
    }

    /* Absolute positioning */
    &.right {
      left: calc(100% + 30px);
      top: 50%;
      transform: translateX(0) translateY(-50%);
      /* CSS border triangles */
      &::before {
        left: calc(6px * -1);
        top: 50%;
        transform: translateX(0) translateY(-50%);
        border-right-color: black;
      }
    }

    /* Absolute positioning */
    &.bottom {
      bottom: calc(30px * -1);
      /* CSS border triangles */
      &::before {
        bottom: 100%;
        border-bottom-color: black;
      }
    }

    /* Absolute positioning */
    &.left {
      left: auto;
      right: calc(100% + 30px);
      top: 50%;
      transform: translateX(0) translateY(-50%);
      /* CSS border triangles */
      &::before {
        left: auto;
        right: calc(6px * -2);
        top: 50%;
        transform: translateX(0) translateY(-50%);
        border-left-color: black;
      }
    }
  }
`
