import styled from "styled-components"
import device from "utils/media"

export const KasBrandsWrapper = styled.div`
  position: relative;
  border: 1px solid #959595;
  padding: 20px;
  text-align: center;
  margin-bottom: 30px;
  @media ${device.lg} {
    margin: 10px 10% 0 10px;
  }
`

export const KasBrandsLogos = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  color: black;
  flex-wrap: wrap;
  text-align: center;
`

export const BrandItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 50%;
  max-width: 50%;
  margin: 0px;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 20px;
  @media ${device.lg} {
    padding: 10px 30px;
  }
  img {
    // max-width: 90%;
    max-height: 140px;
  }
`
