import React from "react"
import styled from "styled-components/macro"
import device from "utils/media"

const LabelStyled = styled.label`
  color: black;
  font-size: 20px;
  font-weight: 500;
  display: ${({ display }) => display ? display : 'block'};
  @media ${device.lg} {
    font-size: 24px;
  }
  margin-bottom: 10px;
`

const Label = ({ text, marginBottom, name, display, ...props }) => {
  return (
    <LabelStyled
      as="label"
      htmlFor={name}
      display={display || "block"}
      {...props}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  )
}

Label.defaultProps = {
  name: "",
}

export default Label
