import React from "react"
import ReactModal from "react-modal"

import { ModalStyled, ModalInner, ModalTransitionStyles } from "./Modal.styled"

ReactModal.setAppElement('#___gatsby')

const Modal = ({
  children,
  isOpen,
  content,
  width,
  scroll,
  hideClose,
  render,
  onRequestClose,
  textAlign,
  ...props
}) => {
  const modalStyles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(36, 36, 36, 0.75)",
      zIndex: 999,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingLeft: "16px",
      paddingRight: "16px",
    },
    content: {
      maxHeight: "calc(100% - 60px)",
      position: "relative",
      margin: "0 auto",
      width: "100%",
      maxWidth: `${width ? width : 1500}px`,
    },
  }

  return (
    <>
      <ModalTransitionStyles />
      <ModalStyled
        {...props}
        isOpen={isOpen}
        closeTimeoutMS={500}
        style={modalStyles}
        shouldCloseOnEsc={true}
        onRequestClose={onRequestClose}
      >
        <ModalInner isOpen={isOpen}>
          {React.cloneElement(children, { onRequestClose: onRequestClose })}
        </ModalInner>
      </ModalStyled>
    </>
  )
}

export default Modal
