import React from "react"
import classNames from "classnames"
import { useField } from "formik"
import styled from "styled-components"
import Error from "components/FormInputs/Error"
import Label from "components/FormInputs/Label"
import device from "utils/media"

const RadioButtonWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 0;

  @media ${device.md} {
    margin-right: ${props => props.theme.spacing.md};
  }

  span {
    color: black;
    font-size: ${props => props.theme.text.sm};
    line-height: 1;
    margin-left: 8px;

    @media ${device.md} {
      font-size: ${props => props.theme.text.sm_2};
    }
  }
`

const RadioButtonStyled = styled.div`
  position: relative;
  width: 30px;
  height: 30px;
  min-width: 30px;
  display: flex;
  flex-direction: column;

  @media ${device.md} {
    width: 50px;
    height: 50px;
    min-width: 50px;
  }

  label {
    background-color: transparent;
    border: 2px solid black;
    border-radius: 50%;
    cursor: pointer;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
  }

  input[type="radio"] {
    visibility: hidden;
  }

  input[type="radio"]:checked + label {
    background-color: ${({ theme }) => theme.color.teal};
    border-color: #000;
  }

  input[type="radio"]:checked + label:after {
    opacity: 1;
  }
`

// Radio input
export const RadioButton = ({
  field: { name, value, onChange, onBlur },
  id,
  label,
}) => {
  return (
    <RadioButtonWrap className="col xs-6 md-3">
      <RadioButtonStyled>
        <input
          name={name}
          id={id}
          type="radio"
          value={id}
          checked={id === value}
          onChange={onChange}
          onBlur={onBlur}
        />
        {/* eslint-disable-next-line */}
        <label htmlFor={id} />
      </RadioButtonStyled>

      <span>{label}</span>
    </RadioButtonWrap>
  )
}

export const RadioButtonGroupStyled = styled.div`
  &.radio-gender {
    @media ${device.lg} {
      padding-left: 30px;
    }
  }
  .row {
    margin-top: ${props => props.theme.spacing.sm};
    margin-bottom: ${props => props.theme.spacing.base};
  }
`

// Radio group
export const RadioButtonGroup = ({
  value,
  error,
  touched,
  id,
  name,
  label,
  className,
  children,
  validationError
}) => {
  const classes = classNames(
    "input-field",
    {
      "is-success": value || (!error && touched),
      "is-error": !!error && touched,
    },
    className
  )

  const [meta /*{ setValue }*/] = useField(name)

  return (
    <RadioButtonGroupStyled role="group" className={classes}>
      {label && <Label htmlFor={id} text={label} display={"block"} />}
      <div className="row">
        {children}
        {touched && <p>{error}</p>}
      </div>
      {validationError && (
          <Error text={meta.error} isVisible={meta.touched && meta.error} />
        )}
    </RadioButtonGroupStyled>
  )
}
