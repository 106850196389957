import styled from "styled-components"
import device from "utils/media"

export const SliderWrap = styled.div`
  margin-bottom: ${props => props.theme.spacing.base};

  @media ${device.lg} {
    margin-bottom: ${props => props.theme.spacing.lg};
  }
  .swiper-container {
    width: 100%;
    min-width: 100%;
  }

  .swiper-slide {
    flex-basis: 85%;
    max-width: 85%;

    @media ${device.sm} {
      flex-basis: 40%;
    }

    @media ${device.xl} {
      flex-basis: 60%;
      max-width: 440px;
    }
  }

  &.large-slides .swiper-slide {
    max-width: 65%;
    flex-basis: 65%;

    @media ${device.lg} {
      max-width: 40%;
      flex-basis: 40%;
    }

    @media ${device.xl} {
      max-width: 33.33%;
      flex-basis: 33.33%;
    }

    @media ${device.xxl} {
      max-width: 440px;
      flex-basis: 25%;
    }
  }

  .kas-popup {
    cursor: pointer;
    img {
      opacity: 1;
      transition: opacity 0.4s ease;
    }
    &:hover {
      img {
        opacity: 0.7;
      }
    }
  }
`

export const Heading = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;

  .slider-nav {
    margin-left: auto;
    display: none;

    @media ${device.md} {
      display: flex;
      min-height: 70px;
    }

    &--short {
      display: flex;

      @media ${device.xl} {
        display: none;
      }
    }
  }
`
