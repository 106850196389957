import { theme } from "utils/theme"

//custom react-select overrides
//docs: https://react-select.com/styles

export const customStyles = {
  container: (provided, state) => ({
    ...provided,
    marginBottom: 0,
    flexGrow: 1,
  }),
  menuPortal: provided => ({
    ...provided,
    zIndex: 999,
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: "pointer",
    backgroundColor: state.isSelected ? theme.color.teal : "white",
    fontSize: "18px",
    fontWeight: 400,
    "&:hover": {
      backgroundColor: theme.color.teal,
      color: "white",
    },
  }),
  input: (provided, state) => ({
    ...provided,
    cursor: "pointer",
    fontSize: "18px",
    fontWeight: 400,
    //zIndex: 500,
    "& input": {
      fontWeight: 400,
    },
  }),
  control: (provided, state) => ({
    ...provided,
    padding: "15px",
    minHeight: 0,
    border: "none",
    borderBottom: "2px solid black",
    boxShadow: "none",
    marginBottom: "1.25rem",
    borderRadius: "0px",
    fontSize: "18px",
    fontWeight: 400,
    paddingLeft: "0",
    "&:hover": {
      border: "none",
      borderBottom: "2px solid black",
    },
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
    fontWeight: 400,
  }),
  singleValue: provided => ({
    ...provided,
    color: "black",
    fontWeight: 400,
    paddingRight: "13px",
  }),
  noOptionsMessage: provided => ({
    ...provided,
    color: "black",
    fontSize: "18px",
    fontWeight: 400,
  }),
  loadingMessage: provided => ({
    ...provided,
    color: "black",
    fontSize: "18px",
    fontWeight: 400,
  }),
  placeholder: provided => ({
    ...provided,
    fontWeight: 400,
  }),
  menuList: provided => ({
    ...provided,
    zIndex: 400,
  }),
}
