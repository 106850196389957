import React, { useState } from "react"
import { TooltipWrap } from "./Tooltip.styled"

const Tooltip = ({ label, ...props }) => {
  let timeout
  const [active, setActive] = useState(false)

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true)
    }, props.delay || 200)
  }

  const hideTip = () => {
    clearInterval(timeout)
    setActive(false)
  }

  return (
    <TooltipWrap onMouseEnter={showTip} onMouseLeave={hideTip}>
      {/* Wrapping */}
      {props.children}
      {active && (
        <div className={`Tooltip-Tip ${props.direction || "top"} ${active ? 'active' : ''}`}>
          {/* Content */}
          {label}
        </div>
      )}
    </TooltipWrap>
  )
}

export default Tooltip
