import React from "react"
import styled from "styled-components/macro"
import { Field, useField } from "formik"

import Error from "components/FormInputs/Error"
import Label from "components/FormInputs/Label"
import device from "utils/media"

const TextInputStyled = styled.div`
  position: relative;
  margin-left: 0;
  // min-width: 150px;
  display: block;
  &.text-postcode {
    @media ${device.lg} {
      padding-left: 30px;
    }
  }
`

const InputStyled = styled(Field).attrs(props => ({
  // component: props.type ? props.type : 'input'
}))
`
  background: none;
  width: 100%;
  padding: 11px 0 11px 0;
  border: none;
  border-radius: 0;
  border-bottom: 2px solid black;
  background-color: transparent;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: ${({ theme }) => theme.text.base};
  color: black;

  &.invalid {
    border-color: ${({ theme }) => theme.color.red};
  }

  &:focus {
    outline: none;
  }
`

// forward ref so we can focus with parent components
const TextInput = React.forwardRef(
  (
    {
      label,
      topLabel,
      name,
      className,
      onButtonClick,
      uncontrolled,
      flag,
      required,
      validationError,
      display,
      ...props
    },
    ref
  ) => {
    const [field, meta /*{ setValue }*/] = useField(name)

    //there are some instances when we don't want the text input to be controlled (e.g. codes input)
    const fieldProps = !uncontrolled ? field : {}

    return (
      <TextInputStyled className={className}>
        {label && <Label name={field.name} text={label} display={"block"} />}

        <InputStyled
          {...fieldProps}
          {...props}
          ref={ref}
          display={display || "block"}
        />

        {validationError && (
          <Error text={meta.error} isVisible={meta.touched && meta.error} />
        )}
      </TextInputStyled>
    )
  }
)

TextInput.defaultProps = {
  validationError: true,
}

export default TextInput
