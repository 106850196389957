import styled from "styled-components"
import device from "utils/media"

export const TraitsSection = styled.div`
  padding: 30px 0;
  color: black;
  h3,
  h4 {
    text-transform: uppercase;
    margin-bottom: 12px;
  }
  @media ${device.md} {
    padding: 40px 0 15px 0;
  }
`

export const TraitsCollection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 0 20px 0;
  color: black;
  flex-wrap: wrap;

  > div:last-child {
    .trait-wrap {
      margin-right: 0 !important;
    }
    .Tooltip-Tip {
      left: calc(50% + 0px) !important;
    }
  }
`

export const TraitWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f06b58;
  width: 56px;
  height: 56px;
  margin: 0 8px 8px 0;
  @media ${device.sm} {
    width: 70px;
    height: 70px;
    margin: 0 16px 16px 0;
  }
`
