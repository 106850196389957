import React, { useEffect } from "react"
import styled from "styled-components"
import ReactSelect from "react-select"
import { useField, useFormikContext } from "formik"
import Error from "components/FormInputs/Error"
import Label from "components/FormInputs/Label"
import { customStyles } from "./styles"
import device from "utils/media"

const SelectStyled = styled.div`
  position: relative;
  margin-left: 0;
  // min-width: 150px;
  display: ${props => (props.display === "flex" ? "flex" : "block")};
  align-items: ${props => (props.display === "flex" ? "center" : "flex-start")};
  margin-bottom: ${props => (props.display === "block" ? "1.25rem" : "0")};
  &.select-country,
  &.select-age {
    @media ${device.lg} {
      padding-right: 30px;
    }
  }
`

const Select = ({
  options,
  name,
  label,
  required,
  topLabel,
  noLabel,
  isMulti,
  isFilter,
  className,
  customHandleOnChange,
  questionId,
  placeholder,
  display,
  icon,
  ...props
}) => {
  const { setFieldValue, setFieldTouched } = useFormikContext()
  const [field, meta] = useField(name)

  //set default value in formik if it's provided
  useEffect(() => {
    props.defaultValue &&
      props.defaultValue.value &&
      setFieldValue(name, props.defaultValue.value)
    // eslint-disable-next-line
  }, [])

  const findValueFromOptions = value => {
    if (isMulti && isFilter && value.length === 0) {
      return []
    }

    return Array.isArray(options)
      ? options.find(option => {
          return option.value === value
        })
      : {}
  }

  const handleOnChange = option => {
    const getVal = option => {
      let selectValue
      if (option) {
        if (!isMulti) {
          selectValue = option.value ? option.value : ""
        } else if (isMulti && option !== null) {
          selectValue = option.map(item => item.value)
        } else {
          selectValue = []
        }
      }
      return selectValue
    }

    customHandleOnChange
      ? customHandleOnChange(name, option.value)
      : setFieldValue(name, getVal(option))

    if (isFilter) {
      props.setSelectFilter(getVal(option))
    }
  }

  const stylesOverride = {
    ...customStyles,
    control: (provided, state) => ({
      ...provided,
      padding: "7px 0 6px 0",
      minHeight: 0,
      border: "none",
      borderBottom: "2px solid black",
      boxShadow: "none",
      marginBottom: "20px",
      borderRadius: "0px",
      fontSize: "18px",
      fontWeight: 400,
      paddingLeft: "0",
      "&:hover": {
        border: "none",
        borderBottom: "2px solid black",
      },
    }),
  }

  return (
    <SelectStyled className={className} display={display || "block"}>
      {label && <Label name={name} text={label} display="block" />}

      <ReactSelect
        name={name}
        styles={stylesOverride}
        closeMenuOnSelect={true}
        // menuPortalTarget={document.body}
        components={{ IndicatorSeparator: () => null }}
        options={options}
        isSearchable={false}
        isMulti={isMulti || false}
        onChange={option => {
          handleOnChange(option)
        }}
        onBlur={() => setFieldTouched(name, true)}
        placeholder={placeholder}
        value={findValueFromOptions(field.value)}
        menuShouldScrollIntoView={true}
        {...props}
      />

      {meta.error && (
        <Error text={meta.error} isVisible={meta.error && meta.touched} />
      )}
    </SelectStyled>
  )
}

export default Select
