import React from "react"
import styled, { createGlobalStyle } from "styled-components"
import ReactModal from "react-modal"
import device from "utils/media"

export const ModalTransitionStyles = createGlobalStyle`
    .ReactModal__Body--open {
        overflow: hidden;
    }

    .ReactModal__Overlay {
        opacity: 0;
        transition: opacity 200ms ease-in-out;
        overflow-y: auto;
    }

    .ReactModal__Overlay--after-open {
        opacity: 1;
    }

    .ReactModal__Overlay--before-close {
        opacity: 0;
    }

    .ReactModal__Content {
        margin-top: 45px;
        margin-bottom: 45px;
        top: 0;

        &:focus {
            outline: none;
        }
    }
`

// export const ModalInner = styled.div``

export const ModalInner = styled(({ isOpen, scroll, ...props }) => (
  <div {...props} />
))
  `
  transition: all 500ms ease;
  transform: translateY(${props => (props.isOpen ? "0" : "5px")});
  opacity: (${props => (props.isOpen ? "1" : "0")});
  overflow-y: ${props => (props.scroll ? "auto" : "initial")};
  position: relative;
  background-color: white;
  animation-name: modalSlide;
  animation-duration: 500ms;
  padding: 25px 25px;
  border-radius: 4px;

  @media ${device.lg} {
    padding: 40px;
  }

  @keyframes modalSlide {
    from {
      transform: translateY(25px);
      opacity: 0;
    }

    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
`

export const ModalStyled = styled(ReactModal)`
  .contrast {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: -70%;
      width: 100%;
      transform: skew(-45deg);
      transform-origin: top;
      z-index: 1;
    }
  }
`
