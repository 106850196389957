import React from "react"

import Modal from "components/Modal/Modal"
// import { Button } from "../../common/Button/Button"
import { ReactComponent as Cross } from "../../../../content/assets/icon-cross.svg"

const KasModal = ({
  children,
  onRequestClose,
  isOpen,
  textAlign,
  ...props
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      textAlign={textAlign}
      shouldCloseOnEsc={true}
    >
      <>
        <Cross
          style={{ cursor: "pointer", marginLeft: "auto", display: "block" }}
          onClick={onRequestClose}
        />

        {children}

        <button
          className="btn btn-small text-uppercase"
          style={{ fontStyle: 'italic' }}
          onClick={onRequestClose}
        >
          Close
        </button>
      </>
    </Modal>
  )
}

/*KasModal.defaultProps = {
  textAlign: "left",
  onRequestClose: () => console.log("No close function defined."),
}*/

export default KasModal
