import React from "react"
import styled from "styled-components"
import SeoComponent from "components/SeoComponent"
import ProfileLookup from "components/Forms/ProfileLookup"
import Layout from "components/Layout"
import HeroBanner from "components/HeroBanner"
import KasCarousel from "components/Kas/KasCarousel"
import device from "utils/media"
import { kasDemoPageData } from "components/Data/Kas/KasDemoPageData"

const KasDemoWrapper = styled.div`
  padding: 0 0 60px 0;
  background-color: #000000;
  margin-top: -60px;

  h1,
  h2 {
    font-size: ${props => props.theme.text.md_1};
    @media ${device.md} {
      font-size: ${props => props.theme.text.lg};
    }
    @media ${device.lg} {
      font-size: ${props => props.theme.text.xl};
    }
    @media ${device.lg} {
      font-size: ${props => props.theme.text.xl_2};
    }
  }
`

const KasDemoMain = styled.div`
  position: relative;
  top: 0;
  background-color: #ffffff;
  &.container.xx-large {
    max-width: 1640px;
  }
`

const KasDemoMainInner = styled.div`
  padding: 60px 0;
`

const KasViewMoreWrapper = styled.div`
  margin-top: 80px;
  color: #ffffff;
  h2 {
    color: #ffffff;
  }
`

const KasDemoPage = () => {
  const page = kasDemoPageData

  return (
    <Layout pageName="kas-demo">
      <SeoComponent
        title="What's My KAS Segment?"
        description="Find out which cohort you belong to and a description of a typical member of your group."
      />
      {page && page.hero_banner && (
        <HeroBanner fields={page.hero_banner} pageName="kas-demo" />
      )}
      <KasDemoWrapper>
        <KasDemoMain id="profile-lookup" className="container xs-no-padding sm-no-padding xx-large">
          <KasDemoMainInner>
            <div className="container x-large">
              <div className="row">
                <div className="col md-12">
                  {page.kasProfiles.profiles && (
                    <ProfileLookup profiles={page.kasProfiles.profiles} />
                  )}
                </div>
              </div>
            </div>
          </KasDemoMainInner>
        </KasDemoMain>

        {page.kasProfiles.profiles.length && (
          <KasViewMoreWrapper>
            <div className="container x-large">
              <KasCarousel
                profiles={page.kasProfiles.profiles}
                heading="View more KAS Profiles"
                slideSize="large"
              />
            </div>
          </KasViewMoreWrapper>
        )}
      </KasDemoWrapper>
    </Layout>
  )
}

export default KasDemoPage
