import React from "react"
import { MetricWrap } from "./KasMetric.styled"

import AgeIcon from "../../../../content/assets/kas/icons/metrics/kas-metric-age.svg"
import GenderIcon from "../../../../content/assets/kas/icons/metrics/kas-metric-gender.svg"
import IncomeIcon from "../../../../content/assets/kas/icons/metrics/kas-metric-income.svg"

const getDetails = metric => {
  let details = null

  switch (metric.type) {
    case "age":
      details = {
        icon: AgeIcon,
        name: "Age",
      }
      break
    case "gender":
      details = {
        icon: GenderIcon,
        name: "Gender",
      }
      break
    case "income":
      details = {
        icon: IncomeIcon,
        name: "Disposable Income",
      }
      break
    default:
      details = null
  }

  return details
}

const KasMetric = ({ metric }) => {
  const details = getDetails(metric)
  return (
    <>
      {details && (
        <MetricWrap>
          <div className="metric--key">
            <div className="metric--key__icon">
              <img src={details.icon} alt={details.name} width="40" />
            </div>
            {details.name}
          </div>
          {metric.type === "gender" ? (
            <div className="metric--value">
              {metric.value === "F" ? "Female" : "Male"}
            </div>
          ) : (
            <div className="metric--value">{metric.value}</div>
          )}
        </MetricWrap>
      )}
    </>
  )
}

export default KasMetric
