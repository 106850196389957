import React, { useState } from "react"
import { Formik, Field, Form } from "formik"
import axios from "axios"
import styled from "styled-components"
import * as Yup from "yup"
import scrollTo from "gatsby-plugin-smoothscroll"
import TextInput from "components/FormInputs/TextInput"
import Select from "components/FormInputs/Select"
import { RadioButtonGroup, RadioButton } from "components/FormInputs/Radio"
import { FormSubmit } from "components/FormInputs"
import Error from "components/FormInputs/Error"
import KasProfile from "components/Kas/KasProfile"
import KasModal from "components/Kas/KasModal"
import device from "utils/media"
import { postcodeRegExp } from "utils/helpers"
import View from "components/View"
import WhatIsKasButton from "components/Kas/WhatIsKasButton"
import { StyledKasTitle } from "components/Kas/KasProfile/KasProfile.styled"

import WhatIsKasImageDesktop from "../../../content/assets/kas/infographics/what-is-kas-infographic--desktop.png"
import WhatIsKasImageMobile from "../../../content/assets/kas/infographics/what-is-kas-infographic--mobile.png"


const StyledForm = styled.div`
  .privacy {
    margin: 30px 0 10px 0;
    font-size: 12px;
    line-height: 1.4;
    @media ${device.lg} {
      font-size: 14px;
    }
  }
`

const ProfileLookupSchema = Yup.object().shape({
  country: Yup.string().required("Required"),
  postcode: Yup.string()
    .uppercase()
    .when("country", {
      is: country => country === "AU",
      then: Yup.string()
        .matches(postcodeRegExp("AU"), "AU postcode is invalid")
        .required("AU postcode required"),
    })
    .when("country", {
      is: country => country === "NZ",
      then: Yup.string()
        .matches(postcodeRegExp("NZ"), "NZ postcode is invalid")
        .required("NZ postcode required"),
    })
    .when("country", {
      is: country => country === "GB",
      then: Yup.string()
        .matches(postcodeRegExp("GB"), "UK postcode is invalid")
        .required("UK postcode required"),
    })
    .when("country", {
      is: country => country === "US",
      then: Yup.string()
        .matches(postcodeRegExp("US"), "US ZIP code is invalid")
        .required("US ZIP code required"),
    }),
  age: Yup.string().required("Required"),
  gender: Yup.string().required("Required"),
})

const countries = [
  { label: "Australia", value: "AU" },
  { label: "New Zealand", value: "NZ" },
  { label: "United Kingdom", value: "GB" },
  { label: "United States", value: "US" },
]

const ageRanges = [
  { label: "18 - 30", value: "24" },
  { label: "31 - 50", value: "40" },
  { label: "51 - 70", value: "60" },
  { label: "70+", value: "76" },
]

const ProfileLookup = ({ profiles }) => {
  const [kasProfileResult, setKasProfileResult] = useState(null)
  const [userCountryCode, setUserCountryCode] = useState(null)
  const [apiError, setApiError] = useState("")
  const [openInfoModal, setOpenInfoModal] = useState(false)

  const initialValues = {
    country: userCountryCode ? userCountryCode : "AU",
    postcode: "",
    age: "24",
    gender: "m",
  }

  const handleSubmit = (values, setSubmitting, resetForm) => {
    // console.log("handleSubmit", values)
    setKasProfileResult(null)
    setApiError("")
    const submittedData = {
      apikey: "vahPZrDazD92ntKw8c4CzTE9TB7MuNkr",
      postcode: values.postcode.toUpperCase(),
      country: values.country,
      gender: values.gender,
      age: values.age,
    }

    axios({
      method: "post",
      url: `https://api.kaptiv.com.au/v1/kas`,
      data: submittedData,
    })
      .then(res => {
        console.log(res)
        if (res && res.data && res.data.kas) {
          if (res.data.kas === "UNKNOWN") {
            setApiError(
              "No profile could be found. Please check your postcode / ZIP code and try again."
            )
          } else {
            let profile = profiles.find(p => p.code === res.data.kas)
            setKasProfileResult({
              profile: profile,
              countryCode: submittedData.country.toLowerCase(),
            })
          }
        } else {
          setApiError("Something went wrong.")
        }
        setSubmitting(false)
        scrollTo("#profile-lookup")
        resetForm()
        setUserCountryCode(submittedData.country)
      })
      .catch(err => {
        if (err.response) {
          // Request made and server responded
          console.log(err.response.data)
          if (err.response.data && err.response.data.message) {
            setApiError(err.response.data.message)
          }
          console.log(err.response.status)
          // console.log(err.response.headers)
        } else if (err.request) {
          // The request was made but no response was received
          console.log(err.request)
          setApiError("The request was made but no response was received")
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", err.message)
          setApiError(err.message)
        }
        scrollTo("#profile-lookup")
        setSubmitting(false)
        setKasProfileResult(null)
      })
  }

  const handleNewSearch = () => {
    setKasProfileResult(null)
    scrollTo("#profile-lookup")
  }

  const toggleInfoModal = () => {
    setOpenInfoModal(!openInfoModal)
  }

  return (
    <div>
      <View>
        {kasProfileResult && kasProfileResult.profile ? (
          <KasProfile
            profile={kasProfileResult.profile}
            countryCode={kasProfileResult.countryCode}
            isResult={true}
            handleNewSearch={handleNewSearch}
            handleInfoModal={toggleInfoModal}
          />
        ) : (
          <>
            <StyledKasTitle>
              <h2>KAS Profile Lookup</h2>
              <WhatIsKasButton handleInfoModal={toggleInfoModal} />
            </StyledKasTitle>
            {apiError && <Error isVisible={true} text={apiError} />}
            <Formik
              initialValues={initialValues}
              validationSchema={ProfileLookupSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                handleSubmit(values, setSubmitting, resetForm)
              }}
            >
              {({ values, isSubmitting, isValid, errors, touched }) => (
                <StyledForm>
                  <Form>
                    <div className="row">
                      <div className="col md-6">
                        <Select
                          label="Select your country"
                          topLabel
                          name="country"
                          defaultValue={initialValues.country}
                          options={countries}
                          display="block"
                          className="select-country"
                        />
                      </div>

                      <div className="col md-6">
                        <TextInput
                          name="postcode"
                          label="Enter your postcode / ZIP code"
                          className="text-postcode"
                        />
                      </div>

                      <div className="col md-6">
                        <Select
                          label="Select your age"
                          topLabel
                          name="age"
                          defaultValue={initialValues.age}
                          options={ageRanges}
                          display="block"
                          className="select-age"
                        />
                      </div>

                      <div className="col md-6">
                        <RadioButtonGroup
                          id="gender"
                          name="gender"
                          value={values.radioGroup}
                          error={errors.radioGroup}
                          touched={touched.radioGroup}
                          label={"Select your gender"}
                          className="radio-gender"
                        >
                          <Field
                            component={RadioButton}
                            name="gender"
                            id="m"
                            label="Male"
                          />
                          <Field
                            component={RadioButton}
                            name="gender"
                            id="f"
                            label="Female"
                          />
                        </RadioButtonGroup>
                      </div>
                      <div className="col sm-12">
                        <FormSubmit
                          isSubmitting={isSubmitting}
                          showLoader={isSubmitting}
                          submittingText="Searching"
                          disabled={!isValid}
                        />
                      </div>
                      <div className="col sm-12">
                        <p className="privacy">
                          WINR Data respects your privacy. The data entered on
                          this screen will not be retained or used for any other
                          purpose than producing the demo results.
                        </p>
                      </div>
                    </div>
                  </Form>
                </StyledForm>
              )}
            </Formik>
          </>
        )}
      </View>
      <KasModal isOpen={openInfoModal} onRequestClose={toggleInfoModal}>
        <h2>What is KAS?</h2>
        <p><strong>WINR Data's Kaptiv Audience Segments (KAS)</strong> is a proprietary data segmentation system used to identify and understand the best audience segments for marketers to focus on - allowing you to predict future performance and deliver higher conversion rates.</p>
        <img className="hidden-until-md" src={WhatIsKasImageDesktop} alt="WINR Data's Kaptiv Audience Segments (KAS)" />
        <img className="hidden-from-md" src={WhatIsKasImageMobile} alt="WINR Data's Kaptiv Audience Segments (KAS)" />
      </KasModal>
    </div>
  )
}

export default ProfileLookup
