import styled from "styled-components"

export const MetricWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  border-bottom: 1px dotted #dcdcdc;
  color: black;
  .metric--key {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 700;
    padding-right: 18px;
    &__icon {
      width: 40px;
      height: 40px;
      margin-right: 18px;
    }
  }
`
