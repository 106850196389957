import React from "react"
import styled from "styled-components/macro"

export const ErrorStyled = styled.p`
  color: ${({ theme }) => theme.color.red};
  font-weight: 500;
  margin: 0 0 8px 0;
  font-size: 12px;
  ${"" /* opacity: ${(props) => (props.isVisible ? 1 : 0)}; */}
  display: ${props => (props.isVisible ? "block" : "none")};
  transition: ${({ theme }) => theme.transition.default};
  pointer-events: none;
  text-align: left;
  &:first-letter {
    text-transform: capitalize;
  }
`

const Error = ({ isVisible, ...props }) => {
  return <ErrorStyled isVisible={isVisible}>{props.text}</ErrorStyled>
}

export default Error
