import styled from "styled-components"
import device from "utils/media"
import { ReactComponent as CornerGraphic } from "../../../../content/assets/kas/icons/icon-corner.svg"

export const KasProfileImageWrapper = styled.div`
  margin-top: ${({ isResult }) => (isResult ? 0 : 20)}px;
  @media ${device.md} {
    margin-top: ${({ isResult }) => (isResult ? 6 : 26)}px;
  }
  .styled-image {
    position: relative;
    padding: 10px 0 10px 10px;
    @media ${device.lg} {
      padding-right: 10%;
    }
    &:after {
      content: "";
      position: absolute;
      width: 80%;
      height: 80%;
      background: #95e3e2;
      top: 0;
      left: 0;
      z-index: 0;
    }
    img {
      position: relative;
      z-index: 1;
    }
  }
`

export const StyledKasTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const SegmentDetails = styled.div`
  position: relative;
  @media ${device.md} {
    padding-right: 30px;
  }
  @media ${device.lg} {
    padding-right: 50px;
  }
  margin-top: ${({ isResult }) => (isResult ? 0 : 20)}px;
  @media ${device.md} {
    margin-top: ${({ isResult }) => (isResult ? 6 : 26)}px;
  }  
`

export const StyledCorner = styled(CornerGraphic)`
  position: absolute;
  right: 0;
  transform: ${({ flip }) => (flip ? "scaleY(-1)" : "none")};
  &.corner-top {
    top: 6px;
  }
  &.corner-bottom {
    bottom: 6px;
  }
`
