export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const getPropertyValue = (obj1, dataToRetrieve) => {
  return dataToRetrieve
    .split(".") // split string based on `.`
    .reduce(function (o, k) {
      return o && o[k] // get inner property if `o` is defined else get `o` and return
    }, obj1) // set initial value as object
}

export const wrapFirstWord = string => {
  if (string.length > 0 && !string.includes("span")) {
    // set new text variable
    let niceText = string
    // set opening / closing spans
    let openSpan = '<span class="first-word">',
      closeSpan = "</span>"
    // make the sentence into an array
    niceText = niceText.split(" ")
    // add open span to the beginning of the array
    niceText.unshift(openSpan)
    // add closing span as the 3rd value in the array
    niceText.splice(2, 0, closeSpan)
    // turn it back into a string
    niceText = niceText.join(" ")
    // append the new HTML to the header
    return (string = niceText)
  }
}

export const preventOrphan = text => {
  return text.replace(/\s(?=[^\s]*$)/g, "&nbsp;")
}

export const checkWindow = () => {
  return typeof window !== "undefined" ? true : false
}

export const postcodeRegExp = countryCode => {
  const auPostcodeRegExp = /^(0[289][0-9]{2}|[13-689][0-9]{3}|2[0-8][0-9]{2}|290[0-9]|291[0-4]|7[0-4][0-9]{2}|7[89][0-9]{2})$/

  let regExp = null

  switch (countryCode) {
    case "AU":
      regExp = auPostcodeRegExp
      break
    case "NZ":
      regExp = /^\d{4}$/
      break
    case "GB":
      regExp = /^(GIR ?0AA|[A-PR-UWYZ]([0-9]{1,2}|([A-HK-Y][0-9]([0-9ABEHMNPRV-Y])?)|[0-9][A-HJKPS-UW]) ?[0-9][ABD-HJLNP-UW-Z]{2})$/
      break
    case "US":
      regExp = /^(\d{5}([-]\d{4})?)$/
      break
    default:
      regExp = auPostcodeRegExp
  }

  return regExp
}
