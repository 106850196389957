const kasProfiles = {
  profiles: [
    {
      code: "01",
      name: "Rising Stars",
      description: `The most affluent female sub-segment of Gen Z, this tribe tend to be living in the most hip and affluent suburbs. They like to keep up to date with the latest gadgets and follow global and environmental issues.`,
      age: "18 - 30",
      gender: "F",
      income: "High",
      generation: "GENZ",
      traits: [
        {
          icon: "cocktails",
          type: "drink",
        },
        {
          icon: "overseas",
          type: "holiday",
        },
        {
          icon: "restaurants",
          type: "food",
        },
        {
          icon: "professional",
          type: "education",
        },
        {
          icon: "gym",
          type: "activity",
        },
      ],
      brands: {
        au: ["apple", "netflix", "virgin-active", "sass-bide"],
        nz: ["apple", "netflix", "virgin-active", "stolen-girlfriends-club"],
        gb: ["apple", "netflix", "virgin-active", "lululemon"],
        us: ["apple", "netflix", "victorias-secret", "lululemon"],
      },
    },
    {
      code: "02",
      name: "Beautiful Beginnings",
      description: `These young fashion conscious women follow the latest tends are are very active on social media. They will most likely live with their parents or be in shared rentals.`,
      age: "18 - 30",
      gender: "F",
      income: "Medium",
      generation: "GENZ",
      traits: [
        {
          icon: "wine",
          type: "drink",
        },
        {
          icon: "camping",
          type: "holiday",
        },
        {
          icon: "food-delivery",
          type: "food",
        },
        {
          icon: "graduate",
          type: "education",
        },
        {
          icon: "shopping",
          type: "activity",
        },
      ],
      brands: {
        au: ["hyundai", "cotton-on", "asos", "bras-things"],
        nz: ["apple", "cotton-on", "topshop", "mamma-mia"],
        gb: ["samsung", "cotton-on", "topshop", "m-s"],
        us: ["samsung", "target", "forever-21", "popsugar"],
      },
    },
    {
      code: "03",
      name: "Part Time Casuals",
      description: `The least affluent female sub-segment of Gen Z, this tribe will most likely live with parents in the economically challenged suburbs. They tend to perform  low skilled, part time  jobs often in retail and hospitality.`,
      age: "18 - 30",
      gender: "F",
      income: "Low",
      generation: "GENZ",
      traits: [
        {
          icon: "take-out",
          type: "drink",
        },
        {
          icon: "stay-home",
          type: "holiday",
        },
        {
          icon: "fast-food",
          type: "food",
        },
        {
          icon: "high-school",
          type: "education",
        },
        {
          icon: "movies",
          type: "activity",
        },
      ],
      brands: {
        au: ["samsung", "target", "mac", "mamma-mia"],
        nz: ["apple", "cotton-on", "topshop", "mamma-mia"],
        gb: ["motorola", "mac-cosmetics", "tk-maxx", "primark"],
        us: ["moto-g-power", "cotton-on", "walmart", "enews"],
      },
    },
    {
      code: "04",
      name: "Young Ambition",
      description: `The most affluent male sub-segment of Gen Z, this tribe tend to be living in the most hip and affluent suburbs. They like to keep up to date with the latest gadgets and follow global and environmental issues.`,
      age: "18 - 30",
      gender: "M",
      income: "High",
      generation: "GENZ",
      traits: [
        {
          icon: "beer",
          type: "drink",
        },
        {
          icon: "overseas",
          type: "holiday",
        },
        {
          icon: "restaurants",
          type: "food",
        },
        {
          icon: "professional",
          type: "education",
        },
        {
          icon: "gym",
          type: "activity",
        },
      ],
      brands: {
        au: ["apple", "netflix", "virgin-active", "mj-bale"],
        nz: ["apple", "netflix", "virgin-active", "good-as-gold"],
        gb: ["apple", "netflix", "virgin-active", "paul-smith"],
        us: ["apple", "netflix", "equinox", "under-armour"],
      },
    },
    {
      code: "05",
      name: "Starting Out",
      description: `These young males live for the moment and enjoy sports, gambling, gaming and motor vehicles.`,
      age: "18 - 30",
      gender: "M",
      income: "Medium",
      generation: "GENZ",
      traits: [
        {
          icon: "beer",
          type: "drink",
        },
        {
          icon: "camping",
          type: "holiday",
        },
        {
          icon: "food-delivery",
          type: "food",
        },
        {
          icon: "graduate",
          type: "education",
        },
        {
          icon: "music",
          type: "activity",
        },
      ],
      brands: {
        au: ["jb-hi-fi", "kayo", "guzman-gomez", "rebel-sport"],
        nz: ["jb-hi-fi", "spark-sport", "betway", "supercheap-auto"],
        gb: ["samsung", "asos", "frankie-bennys", "nike"],
        us: ["samsung", "espn", "fanduel", "nike"],
      },
    },
    {
      code: "06",
      name: "Cash Strapped",
      description: `The least affluent male sub-segment of Gen Z, this tribe will most likely live with parents in the most  economically challenged suburbs. They tend to perform  low skilled, part time  jobs often in construction and manufacturing.`,
      age: "18 - 30",
      gender: "M",
      income: "Low",
      generation: "GENZ",
      traits: [
        {
          icon: "take-out",
          type: "drink",
        },
        {
          icon: "stay-home",
          type: "holiday",
        },
        {
          icon: "fast-food",
          type: "food",
        },
        {
          icon: "high-school",
          type: "education",
        },
        {
          icon: "sport",
          type: "activity",
        },
      ],
      brands: {
        au: ["samsung", "camping-world", "ladbrokes", "supercheap-auto"],
        nz: ["jb-hi-fi", "spark-sport", "ladbrokes", "supercheap-auto"],
        gb: ["motorola", "adidas", "mcdonalds", "jd-sports"],
        us: ["moto-g-power", "ufc", "taco-bell", "autozone"],
      },
    },
    {
      code: "07",
      name: "Income & Assets",
      description: `The most affluent female segment of Gen Y can be found renting or buying their first home in exclusive suburbs. Having most likely graduated, they have good career prospects and rising incomes.`,
      age: "31 - 50",
      gender: "F",
      income: "High",
      generation: "GENY",
      traits: [
        {
          icon: "cocktails",
          type: "drink",
        },
        {
          icon: "overseas",
          type: "holiday",
        },
        {
          icon: "restaurants",
          type: "food",
        },
        {
          icon: "professional",
          type: "education",
        },
        {
          icon: "fashion",
          type: "activity",
        },
      ],
      brands: {
        au: ["audi", "lv", "burberry", "marley-spoon"],
        nz: ["audi", "lv", "burberry", "karen-walker"],
        gb: ["audi", "lv", "burberry", "whistles"],
        us: ["audi", "neiman-marcus", "macys", "sweaty-betty"],
      },
    },
    {
      code: "08",
      name: "Metro Mums",
      description: `These middle income females will be balancing a career with raising their young families in middle class suburbs.`,
      age: "31 - 50",
      gender: "F",
      income: "Medium",
      generation: "GENY",
      traits: [
        {
          icon: "wine",
          type: "drink",
        },
        {
          icon: "beach",
          type: "holiday",
        },
        {
          icon: "food-delivery",
          type: "food",
        },
        {
          icon: "graduate",
          type: "education",
        },
        {
          icon: "gym",
          type: "activity",
        },
      ],
      brands: {
        au: ["lululemon", "temple-webster", "koala", "bed-bath-n-table"],
        nz: ["lululemon", "temple-webster", "ikea", "citta"],
        gb: ["lululemon", "waitrose", "seat", "next"],
        us: ["lululemon", "amazon", "casper", "etsy"],
      },
    },
    {
      code: "09",
      name: "Humble Homes",
      description: `These family first low income females are savvy savers who are always looking for creative ways to cut costs and provide for their young families.`,
      age: "31 - 50",
      gender: "F",
      income: "Low",
      generation: "GENY",
      traits: [
        {
          icon: "take-out",
          type: "drink",
        },
        {
          icon: "stay-home",
          type: "holiday",
        },
        {
          icon: "fast-food",
          type: "food",
        },
        {
          icon: "high-school",
          type: "education",
        },
        {
          icon: "tv",
          type: "activity",
        },
      ],
      brands: {
        au: ["kmart", "coles", "womens-weekly-au", "masterchef"],
        nz: ["kmart", "countdown", "womens-weekly-nz", "masterchef"],
        gb: ["primark", "tesco", "daily-mirror", "masterchef"],
        us: ["walmart", "tmz", "costco", "foodnetwork"],
      },
    },
    {
      code: "10",
      name: "Smart Money",
      description: `The most affluent male segment of Gen Y can be found renting or buying their first home in exclusive suburbs. Having most likely graduated, they have good career prospects and rising incomes.`,
      age: "31 - 50",
      gender: "M",
      income: "High",
      generation: "GENY",
      traits: [
        {
          icon: "beer",
          type: "drink",
        },
        {
          icon: "beach",
          type: "holiday",
        },
        {
          icon: "restaurants",
          type: "food",
        },
        {
          icon: "professional",
          type: "education",
        },
        {
          icon: "gym",
          type: "activity",
        },
      ],
      brands: {
        au: ["bmw", "qantas", "rolex", "f1"],
        nz: ["audi", "lv", "burberry", "f1"],
        gb: ["audi", "garmin", "bannatyne", "f1"],
        us: ["tesla", "brooks-brothers", "burberry", "f1"],
      },
    },
    {
      code: "11",
      name: "Giving It A Go",
      description: `These males form part of the core of the workforce in skilled trades and office clerical. Typically sports enthusiasts they share a passion for the outdoors.`,
      age: "31 - 50",
      gender: "M",
      income: "Medium",
      generation: "GENY",
      traits: [
        {
          icon: "beer",
          type: "drink",
        },
        {
          icon: "overseas",
          type: "holiday",
        },
        {
          icon: "food-delivery",
          type: "food",
        },
        {
          icon: "graduate",
          type: "education",
        },
        {
          icon: "sport",
          type: "activity",
        },
      ],
      brands: {
        au: ["bunnings", "camping-world", "tab", "afl"],
        nz: ["bunnings", "camping-world", "spark-sport", "all-blacks"],
        gb: ["vw", "fitbit", "william-hill", "premier-league"],
        us: ["home-depot", "wrangler", "fanduel", "nfl"],
      },
    },
    {
      code: "12",
      name: "Modest Means",
      description: `The least affluent male sub-segment of Gen Y, this tribe will most likely renting with a partner and young family in the most economically challenged suburbs. They tend to perform low skilled, part time jobs often in construction and manufacturing.`,
      age: "31 - 50",
      gender: "M",
      income: "Low",
      generation: "GENY",
      traits: [
        {
          icon: "take-out",
          type: "drink",
        },
        {
          icon: "stay-home",
          type: "holiday",
        },
        {
          icon: "fast-food",
          type: "food",
        },
        {
          icon: "high-school",
          type: "education",
        },
        {
          icon: "gambling",
          type: "activity",
        },
      ],
      brands: {
        au: ["bunnings", "vb", "bluebet", "nrl"],
        nz: ["bunnings", "camping-world", "betway", "all-blacks"],
        gb: ["wetherspoons", "halfords", "coral", "wbc-boxing"],
        us: ["ford", "autozone", "miller-lite", "nfl"],
      },
    },
    {
      code: "13",
      name: "Wealthy & Wise",
      description: `The most affluent segment of Gen X can be found raising their families in exclusive suburbs. They have high career prospects and strive to provide their families with the finer things in life.`,
      age: "51 - 70",
      gender: "F",
      income: "High",
      generation: "GENX",
      traits: [
        {
          icon: "champagne",
          type: "drink",
        },
        {
          icon: "overseas",
          type: "holiday",
        },
        {
          icon: "restaurants",
          type: "food",
        },
        {
          icon: "professional",
          type: "education",
        },
        {
          icon: "theatre",
          type: "activity",
        },
      ],
      brands: {
        au: ["veuve-clicqout", "david-jones", "mercedes-benz", "ritz-carlton"],
        nz: ["veuve-clicqout", "trelise-cooper", "mercedes-benz", "park-hyatt"],
        gb: ["veuve-clicqout", "john-lewis", "calvin-klein", "mini"],
        us: ["veuve-clicqout", "neiman-marcus", "mercedes-benz", "hermes"],
      },
    },
    {
      code: "14",
      name: "Homes & Wealth",
      description: `These middle class women live a comfortable lifestyle usually with one or more pets. Retirement is around the corner and their focus is keeping good health and achieving financial security.`,
      age: "51 - 70",
      gender: "F",
      income: "Medium",
      generation: "GENX",
      traits: [
        {
          icon: "wine",
          type: "drink",
        },
        {
          icon: "pets",
          type: "holiday",
        },
        {
          icon: "cook-at-home",
          type: "food",
        },
        {
          icon: "high-school",
          type: "education",
        },
        {
          icon: "cinema",
          type: "activity",
        },
      ],
      brands: {
        au: ["petbarn", "dymocks", "subaru", "clinique"],
        nz: ["animates", "whitcoulls", "tvnz", "clinique"],
        gb: ["waitrose", "renault", "bbc", "clinique"],
        us: ["petco", "barnes-noble", "buick", "clinique"],
      },
    },
    {
      code: "15",
      name: "Frugal Homes",
      description: `The least affluent female sub-segment of Gen X, this tribe will most likely renting with a partner and young adult children in the most  economically challenged suburbs. They tend to perform  low skilled, part time  jobs often in retail and clerical.`,
      age: "51 - 70",
      gender: "F",
      income: "Low",
      generation: "GENX",
      traits: [
        {
          icon: "take-out",
          type: "drink",
        },
        {
          icon: "stay-home",
          type: "holiday",
        },
        {
          icon: "cook-at-home",
          type: "food",
        },
        {
          icon: "high-school",
          type: "education",
        },
        {
          icon: "cooking",
          type: "activity",
        },
      ],
      brands: {
        au: ["target", "aldi", "kia", "kmart"],
        nz: ["animates", "whitcoulls", "kmart", "paknsave"],
        gb: ["iceland", "gmtv", "argos", "avon"],
        us: ["oreo", "walmart", "home-shopping-network", "betty-crocker"],
      },
    },
    {
      code: "16",
      name: "Comfortably Cruising",
      description: `The most affluent segment of Gen X can be found raising their families in exclusive suburbs. These wealthy individuals are actively seeking to protect their financial security while they transition into retirement.`,
      age: "51 - 70",
      gender: "M",
      income: "High",
      generation: "GENX",
      traits: [
        {
          icon: "champagne",
          type: "drink",
        },
        {
          icon: "overseas",
          type: "holiday",
        },
        {
          icon: "restaurants",
          type: "food",
        },
        {
          icon: "professional",
          type: "education",
        },
        {
          icon: "sports-car",
          type: "activity",
        },
      ],
      brands: {
        au: ["rugby-union", "david-jones", "tesla", "ritz-carlton"],
        nz: ["veuve-clicqout", "david-jones", "mercedes-benz", "ritz-carlton"],
        gb: ["ray-ban", "john-lewis", "mercedes-benz", "rugby-union"],
        us: ["tag-heuer", "ralph-lauren", "tesla", "ritz-carlton"],
      },
    },
    {
      code: "17",
      name: "Garages & Gardens",
      description: `These males are approaching retirement, typically from services and blue collar professions. These males are working hard to secure their retirement whilst enjoying sports and the outdoors.`,
      age: "51 - 70",
      gender: "M",
      income: "Medium",
      generation: "GENX",
      traits: [
        {
          icon: "beer",
          type: "drink",
        },
        {
          icon: "camping",
          type: "holiday",
        },
        {
          icon: "cook-at-home",
          type: "food",
        },
        {
          icon: "trades",
          type: "education",
        },
        {
          icon: "gardening",
          type: "activity",
        },
      ],
      brands: {
        au: ["holden", "fox-sports", "ladbrokes", "stihl"],
        nz: ["bunnings", "steinlager", "betfair", "big-bash-league"],
        gb: ["b-q", "bbc-sports", "ford", "premier-league"],
        us: ["miller-lite", "chevrolet", "ace-hardware", "mlb"],
      },
    },
    {
      code: "18",
      name: "On The Tools",
      description: `The least affluent male sub-segment of Gen X, this tribe will most likely renting with a partner and young adult children in the most  economically challenged suburbs. They tend to perform  low skilled, part time jobs often in construction and manufacturing.`,
      age: "51 - 70",
      gender: "M",
      income: "Low",
      generation: "GENX",
      traits: [
        {
          icon: "take-out",
          type: "drink",
        },
        {
          icon: "stay-at-home",
          type: "holiday",
        },
        {
          icon: "cook-at-home",
          type: "food",
        },
        {
          icon: "trades",
          type: "education",
        },
        {
          icon: "diy",
          type: "activity",
        },
      ],
      brands: {
        au: ["ford", "carlton", "ryobi", "nrl"],
        nz: ["bunnings", "speights", "betfair", "all-blacks"],
        gb: ["homebase", "bbc-sports", "the-sun", "william-hill"],
        us: ["blue-ribbon", "ford", "home-depot", "nfl"],
      },
    },
    {
      code: "19",
      name: "Champagne Seniors",
      description: `These senior females live in the most affluent neighbourhoods. They are making the most of their retirement by travelling and enjoying many of life's luxuries.`,
      age: "70+",
      gender: "F",
      income: "High",
      generation: "BABYBOOMERS",
      traits: [
        {
          icon: "champagne",
          type: "drink",
        },
        {
          icon: "cruise",
          type: "holiday",
        },
        {
          icon: "restaurants",
          type: "food",
        },
        {
          icon: "graduate",
          type: "education",
        },
        {
          icon: "the-arts",
          type: "activity",
        },
      ],
      brands: {
        au: [
          "veuve-clicqout",
          "viking-cruises",
          "estee-lauder",
          "mercedes-benz",
        ],
        nz: ["veuve-clicqout", "viking-cruises", "estee-lauder", "chanel"],
        gb: [
          "veuve-clicqout",
          "viking-cruises",
          "estee-lauder",
          "fortnum-mason",
        ],
        us: ["veuve-clicqout", "cartier", "estee-lauder", "bmw"],
      },
    },
    {
      code: "20",
      name: "Prudent Pensions",
      description: `These senior females are enjoying a comfortable retirement. They are active in the community and support local charities. Often provide child care cover for grand children.`,
      age: "70+",
      gender: "F",
      income: "Medium",
      generation: "BABYBOOMERS",
      traits: [
        {
          icon: "wine",
          type: "drink",
        },
        {
          icon: "overseas",
          type: "holiday",
        },
        {
          icon: "cook-at-home",
          type: "food",
        },
        {
          icon: "high-school",
          type: "education",
        },
        {
          icon: "pets",
          type: "activity",
        },
      ],
      brands: {
        au: ["family-circle", "harris-scarfe", "foxtel", "noni-b"],
        nz: ["flight-centre", "foodstuffs", "spark-nz", "oil-of-olay"],
        gb: ["boots", "bbc", "birds-eye", "wh-smith"],
        us: [
          "jc-penney",
          "better-homes-gardens",
          "oil-of-olay",
          "dish-network",
        ],
      },
    },
    {
      code: "21",
      name: "Grandkids & Causes",
      description: `The least affluent female retirees, this tribe will most likely living in the most economically challenged suburbs. They tend to stay close to home due to limited finances.`,
      age: "70+",
      gender: "F",
      income: "Low",
      generation: "BABYBOOMERS",
      traits: [
        {
          icon: "take-out",
          type: "drink",
        },
        {
          icon: "stay-home",
          type: "holiday",
        },
        {
          icon: "cook-at-home",
          type: "food",
        },
        {
          icon: "high-school",
          type: "education",
        },
        {
          icon: "cooking",
          type: "activity",
        },
      ],
      brands: {
        au: ["rsl", "better-homes-gardens", "black-gold", "spotlight"],
        nz: ["readers-digest", "tip-top", "chemist-warehouse", "tvnz"],
        gb: ["greggs", "mecca", "mccain", "beefeater"],
        us: ["readers-digest", "home-depot", "walmart", "betty-crocker"],
      },
    },
    {
      code: "22",
      name: "Silver Foxes",
      description: `Living in most prestigious suburbs, these wealthy, well educated, retired males enjoy a round of golf and a glass of red.`,
      age: "70+",
      gender: "M",
      income: "High",
      generation: "BABYBOOMERS",
      traits: [
        {
          icon: "champagne",
          type: "drink",
        },
        {
          icon: "cruise",
          type: "holiday",
        },
        {
          icon: "restaurants",
          type: "food",
        },
        {
          icon: "professional",
          type: "education",
        },
        {
          icon: "golf",
          type: "activity",
        },
      ],
      brands: {
        au: ["penfolds", "afl", "golfworld", "lexus"],
        nz: ["titleist", "all-blacks", "porsche", "air-nz"],
        gb: ["glenfiddich", "porsche", "titleist", "british-airways"],
        us: ["chivas-regal", "nfl", "callaway", "porsche"],
      },
    },
    {
      code: "23",
      name: "Lawns & Lotteries",
      description: `These retired males still remain physically active, they enjoy watching sports, lotteries and travel.`,
      age: "70+",
      gender: "M",
      income: "Medium",
      generation: "BABYBOOMERS",
      traits: [
        {
          icon: "beer",
          type: "drink",
        },
        {
          icon: "overseas",
          type: "holiday",
        },
        {
          icon: "cook-at-home",
          type: "food",
        },
        {
          icon: "trades",
          type: "education",
        },
        {
          icon: "gardening",
          type: "activity",
        },
      ],
      brands: {
        au: ["victa", "bcf", "oz-lotteries", "jetstar"],
        nz: ["victa", "steinlager", "lotto-nz", "jetstar"],
        gb: ["homebase", "gardena", "premier-league", "morrisons"],
        us: ["ace-hardware", "dewalt", "megamillions", "fox-news"],
      },
    },
    {
      code: "24",
      name: "Thrifty Retirees",
      description: `The least affluent male retirees, this tribe will most likely living in the most economically challenged suburbs. They tend to stay close to home and garden due to limited finances.`,
      age: "70+",
      gender: "M",
      income: "Low",
      generation: "BABYBOOMERS",
      traits: [
        {
          icon: "take-out",
          type: "drink",
        },
        {
          icon: "stay-home",
          type: "holiday",
        },
        {
          icon: "cook-at-home",
          type: "food",
        },
        {
          icon: "trades",
          type: "education",
        },
        {
          icon: "car-maintenance",
          type: "activity",
        },
      ],
      brands: {
        au: ["rsl", "tab", "tooheys", "readers-digest"],
        nz: ["stanley", "speights", "lotto-nz", "jetstar"],
        gb: ["greggs", "wetherspoons", "the-national-lottery", "stanley"],
        us: ["home-depot", "stanley", "wild-turkey", "red-baron"],
      },
    },
  ],
}

export { kasProfiles }
