import React from "react"
import { getPropertyValue } from "utils/helpers"
import { KasBrandsWrapper, KasBrandsLogos, BrandItem } from "./KasBrands.styled"

import AceHardware from "../../../../content/assets/kas/brands/ace-hardware-logo.svg"
import Adidas from "../../../../content/assets/kas/brands/adidas-logo.svg"
import Afl from "../../../../content/assets/kas/brands/afl-logo.svg"
import AirNz from "../../../../content/assets/kas/brands/air-nz-logo.svg"
import Aldi from "../../../../content/assets/kas/brands/aldi-logo.svg"
import AllBlacks from "../../../../content/assets/kas/brands/all-blacks-logo.svg"
import Amazon from "../../../../content/assets/kas/brands/amazon-logo.svg"
import Animates from "../../../../content/assets/kas/brands/animates-logo.svg"
import Apple from "../../../../content/assets/kas/brands/apple-logo.svg"
import Argos from "../../../../content/assets/kas/brands/argos-logo.svg"
import Asos from "../../../../content/assets/kas/brands/asos-logo.svg"
import Audi from "../../../../content/assets/kas/brands/audi-logo.svg"
import AutoZone from "../../../../content/assets/kas/brands/autozone-logo.svg"
import Avon from "../../../../content/assets/kas/brands/avon-logo.svg"
import Bannatyne from "../../../../content/assets/kas/brands/bannatyne-logo.png"
import BarnesNoble from "../../../../content/assets/kas/brands/barnes-noble-logo.svg"
import Bbc from "../../../../content/assets/kas/brands/bbc-logo.svg"
import BbcSport from "../../../../content/assets/kas/brands/bbc-sport-logo.svg"
import Bcf from "../../../../content/assets/kas/brands/bcf-logo.svg"
import BedBathTable from "../../../../content/assets/kas/brands/bed-bath-n-table-logo.svg"
import Beefeater from "../../../../content/assets/kas/brands/beefeater-uk-logo.svg"
import Betfair from "../../../../content/assets/kas/brands/betfair-logo.svg"
import BetterHomesGardens from "../../../../content/assets/kas/brands/better-homes-and-gardens-logo.svg"
import BettyCrocker from "../../../../content/assets/kas/brands/betty-crocker-logo.svg"
import Betway from "../../../../content/assets/kas/brands/betway-logo.svg"
import BigBashLeague from "../../../../content/assets/kas/brands/big-bash-league-logo.png"
import BirdsEye from "../../../../content/assets/kas/brands/birds-eye-logo.jpg"
import BlackGold from "../../../../content/assets/kas/brands/black-gold-logo.jpg"
import BlueBet from "../../../../content/assets/kas/brands/bluebet-logo.png"
import BlueRibbon from "../../../../content/assets/kas/brands/pabst-blue-ribbon-logo.svg"
import Bmw from "../../../../content/assets/kas/brands/bmw-logo.svg"
import Boots from "../../../../content/assets/kas/brands/boots-logo.svg"
import Bq from "../../../../content/assets/kas/brands/b-q-logo.svg"
import BrasThings from "../../../../content/assets/kas/brands/bras-n-things-logo.svg"
import BritishAirways from "../../../../content/assets/kas/brands/british-airways-logo.svg"
import BrooksBrothers from "../../../../content/assets/kas/brands/brooks-brothers-logo.svg"
import Buick from "../../../../content/assets/kas/brands/buick-logo.png"
import Bunnings from "../../../../content/assets/kas/brands/bunnings-warehouse-logo.svg"
import Burberry from "../../../../content/assets/kas/brands/burberry-logo.svg"
import Callaway from "../../../../content/assets/kas/brands/callaway-golf-logo.svg"
import CalvinKlein from "../../../../content/assets/kas/brands/calvin-klein-logo.svg"
import CampingWorld from "../../../../content/assets/kas/brands/camping-world-logo.png"
import Carlton from "../../../../content/assets/kas/brands/carlton-logo.svg"
import Cartier from "../../../../content/assets/kas/brands/cartier-logo.svg"
import Casper from "../../../../content/assets/kas/brands/casper-logo.svg"
import Chanel from "../../../../content/assets/kas/brands/chanel-logo.svg"
import ChemistWarehouse from "../../../../content/assets/kas/brands/chemist-warehouse-logo.svg"
import Chevrolet from "../../../../content/assets/kas/brands/chevrolet-logo.svg"
import ChivasRegal from "../../../../content/assets/kas/brands/chivas-regal-logo.svg"
import Citta from "../../../../content/assets/kas/brands/citta-logo.png"
import Clinique from "../../../../content/assets/kas/brands/clinique-logo.svg"
import Coles from "../../../../content/assets/kas/brands/coles-logo.svg"
import Coral from "../../../../content/assets/kas/brands/coral-logo.svg"
import Costco from "../../../../content/assets/kas/brands/costco-logo.svg"
import CottonOn from "../../../../content/assets/kas/brands/cotton-on-logo.svg"
import Countdown from "../../../../content/assets/kas/brands/countdown-logo.svg"
import DailyMirror from "../../../../content/assets/kas/brands/daily-mirror-logo.svg"
import DavidJones from "../../../../content/assets/kas/brands/david-jones-logo.svg"
import Dewalt from "../../../../content/assets/kas/brands/dewalt-logo.svg"
import DishNetwork from "../../../../content/assets/kas/brands/dish-network-logo.svg"
import Dymocks from "../../../../content/assets/kas/brands/dymocks-logo.svg"
import Enews from "../../../../content/assets/kas/brands/e-news-logo.svg"
import Equinox from "../../../../content/assets/kas/brands/equinox-logo.svg"
import Espn from "../../../../content/assets/kas/brands/espn-logo.svg"
import EsteeLauder from "../../../../content/assets/kas/brands/estee-lauder-logo.svg"
import Etsy from "../../../../content/assets/kas/brands/etsy-logo.svg"
import F1 from "../../../../content/assets/kas/brands/f1-logo.svg"
import FamilyCircle from "../../../../content/assets/kas/brands/family-circle-logo.jpg"
import Fanduel from "../../../../content/assets/kas/brands/fanduel-logo.svg"
import Fitbit from "../../../../content/assets/kas/brands/fitbit-logo.svg"
import FlightCentre from "../../../../content/assets/kas/brands/flight-centre-logo.svg"
import FoodNetwork from "../../../../content/assets/kas/brands/food-network-logo.svg"
import Foodstuffs from "../../../../content/assets/kas/brands/foodstuffs-logo.svg"
import Ford from "../../../../content/assets/kas/brands/ford-logo.svg"
import Forever21 from "../../../../content/assets/kas/brands/forever-21-logo.svg"
import FortnumMason from "../../../../content/assets/kas/brands/fortnum-mason-logo.svg"
import FoxNews from "../../../../content/assets/kas/brands/fox-news-logo.svg"
import FoxSports from "../../../../content/assets/kas/brands/fox-sports-logo.svg"
import Foxtel from "../../../../content/assets/kas/brands/foxtel-logo.svg"
import FrankieBennys from "../../../../content/assets/kas/brands/frankie-and-bennys-logo.svg"
import Gardena from "../../../../content/assets/kas/brands/gardena-logo.svg"
import Garmin from "../../../../content/assets/kas/brands/garmin-logo.svg"
import Glenfiddich from "../../../../content/assets/kas/brands/glenfiddich-logo.svg"
import Gmtv from "../../../../content/assets/kas/brands/gmtv-logo.svg"
import GolfWarehouse from "../../../../content/assets/kas/brands/golf-warehouse-logo.png"
import GolfWorld from "../../../../content/assets/kas/brands/golf-world-logo.png"
import GoodAsGold from "../../../../content/assets/kas/brands/good-as-gold-logo.png"
import Greggs from "../../../../content/assets/kas/brands/greggs-logo.png"
import GuzmanGomez from "../../../../content/assets/kas/brands/guzman-y-gomez-logo.svg"
import Halfords from "../../../../content/assets/kas/brands/halfords-logo.svg"
import HarrisScarfe from "../../../../content/assets/kas/brands/harris-scarfe-logo.svg"
import Hermes from "../../../../content/assets/kas/brands/hermes-logo.svg"
import Holden from "../../../../content/assets/kas/brands/holden-logo.png"
import Homebase from "../../../../content/assets/kas/brands/homebase-logo.svg"
import HomeDepot from "../../../../content/assets/kas/brands/home-depot-logo.svg"
import Hsn from "../../../../content/assets/kas/brands/hsn-logo.svg"
import HuntingFishing from "../../../../content/assets/kas/brands/hunting-fishing-logo.svg"
import Hyundai from "../../../../content/assets/kas/brands/hyundai-logo.svg"
import Iceland from "../../../../content/assets/kas/brands/iceland-logo.svg"
import Ikea from "../../../../content/assets/kas/brands/ikea-logo.svg"
import JbHiFi from "../../../../content/assets/kas/brands/jb-hi-fi-logo.svg"
import JcPenney from "../../../../content/assets/kas/brands/jcpenney-logo.svg"
import JdSports from "../../../../content/assets/kas/brands/jd-sports-logo.svg"
import Jetstar from "../../../../content/assets/kas/brands/jetstar-logo.svg"
import JohnLewis from "../../../../content/assets/kas/brands/john-lewis-partners-logo.svg"
import KarenWalker from "../../../../content/assets/kas/brands/karen-walker-logo.svg"
import Kayo from "../../../../content/assets/kas/brands/kayo-logo.svg"
import Kia from "../../../../content/assets/kas/brands/kia-logo.svg"
import Kmart from "../../../../content/assets/kas/brands/kmart-logo.svg"
import Koala from "../../../../content/assets/kas/brands/koala-logo.svg"
import Ladbrokes from "../../../../content/assets/kas/brands/ladbrokes-logo.png"
import Lexus from "../../../../content/assets/kas/brands/lexus-logo.svg"
import LottoNz from "../../../../content/assets/kas/brands/lotto-nz-logo.jpg"
import LouisVuitton from "../../../../content/assets/kas/brands/louis-vuitton-logo.svg"
import Lululemon from "../../../../content/assets/kas/brands/lululemon-logo.svg"
import Mac from "../../../../content/assets/kas/brands/mac-cosmetics-logo.png"
import Macys from "../../../../content/assets/kas/brands/macys-logo.svg"
import Mamamia from "../../../../content/assets/kas/brands/mamamia-logo.png"
import MarleySpoon from "../../../../content/assets/kas/brands/marley-spoon-logo.svg"
import Masterchef from "../../../../content/assets/kas/brands/masterchef-logo.svg"
import McCain from "../../../../content/assets/kas/brands/mccain-logo.svg"
import McDonalds from "../../../../content/assets/kas/brands/mcdonalds-logo.svg"
import Mecca from "../../../../content/assets/kas/brands/mecca-logo.svg"
import MegaMillions from "../../../../content/assets/kas/brands/mega-millions-logo.svg"
import MercedesBenz from "../../../../content/assets/kas/brands/mercedes-benz-logo.svg"
import MillerLite from "../../../../content/assets/kas/brands/miller-lite-logo.svg"
import Mini from "../../../../content/assets/kas/brands/mini-logo.svg"
import MjBale from "../../../../content/assets/kas/brands/mj-bale-logo.png"
import Mlb from "../../../../content/assets/kas/brands/mlb-logo.svg"
import Morrisons from "../../../../content/assets/kas/brands/morrisons-logo.svg"
import MotoG from "../../../../content/assets/kas/brands/moto-g-power-logo.svg"
import Motorola from "../../../../content/assets/kas/brands/motorola-logo.svg"
import Ms from "../../../../content/assets/kas/brands/m-s-logo.svg"
import NeimanMarcus from "../../../../content/assets/kas/brands/neiman-marcus-logo.svg"
import Netflix from "../../../../content/assets/kas/brands/netflix-logo.svg"
import Next from "../../../../content/assets/kas/brands/next-logo.svg"
import Nfl from "../../../../content/assets/kas/brands/nfl-logo.svg"
import Nike from "../../../../content/assets/kas/brands/nike-logo.svg"
import NoniB from "../../../../content/assets/kas/brands/noni-b-logo.png"
import Nrl from "../../../../content/assets/kas/brands/nrl-logo.svg"
import OilOfOlay from "../../../../content/assets/kas/brands/oil-of-olay-logo.svg"
import Oreo from "../../../../content/assets/kas/brands/oreo-logo.svg"
import OzLotteries from "../../../../content/assets/kas/brands/oz-lotteries-logo.svg"
import PakNSave from "../../../../content/assets/kas/brands/paknsave-logo.svg"
import ParkHyatt from "../../../../content/assets/kas/brands/park-hyatt-logo.svg"
import PaulSmith from "../../../../content/assets/kas/brands/paul-smith-logo.svg"
import Penfolds from "../../../../content/assets/kas/brands/penfolds-logo.png"
import Petbarn from "../../../../content/assets/kas/brands/petbarn-logo.svg"
import Petco from "../../../../content/assets/kas/brands/petco-logo.svg"
import Popsugar from "../../../../content/assets/kas/brands/popsugar-logo.png"
import Porsche from "../../../../content/assets/kas/brands/porsche-logo.png"
import PremierLeague from "../../../../content/assets/kas/brands/premier-league-logo.svg"
import Primark from "../../../../content/assets/kas/brands/primark-logo.svg"
import Qantas from "../../../../content/assets/kas/brands/qantas-logo.svg"
import RalphLauren from "../../../../content/assets/kas/brands/ralph-lauren-logo.svg"
import RayBan from "../../../../content/assets/kas/brands/ray-ban-logo.svg"
import ReadersDigest from "../../../../content/assets/kas/brands/readers-digest-logo.png"
import RebelSport from "../../../../content/assets/kas/brands/rebel-sport-logo.svg"
import RedBaron from "../../../../content/assets/kas/brands/red-baron-logo.png"
import Renault from "../../../../content/assets/kas/brands/renault-logo.svg"
import RitzCarlton from "../../../../content/assets/kas/brands/ritz-carlton-logo.svg"
import Rolex from "../../../../content/assets/kas/brands/rolex-logo.svg"
import Rsl from "../../../../content/assets/kas/brands/rsl-australia-logo.svg"
import Ryobi from "../../../../content/assets/kas/brands/ryobi-logo.svg"
import Samsung from "../../../../content/assets/kas/brands/samsung-logo.svg"
import SassBide from "../../../../content/assets/kas/brands/sass-bide-logo.svg"
import Seat from "../../../../content/assets/kas/brands/seat-logo.svg"
import SkyNz from "../../../../content/assets/kas/brands/sky-nz-logo.png"
import SparkSport from "../../../../content/assets/kas/brands/spark-sport-logo.svg"
import SparkNz from "../../../../content/assets/kas/brands/spark-nz-logo.svg"
import Speights from "../../../../content/assets/kas/brands/speights-logo.png"
import Spotlight from "../../../../content/assets/kas/brands/spotlight-logo.svg"
import Stanley from "../../../../content/assets/kas/brands/stanley-logo.svg"
import Steinlager from "../../../../content/assets/kas/brands/steinlager-logo.svg"
import Stihl from "../../../../content/assets/kas/brands/stihl-logo.svg"
import StolenGirlfriendsClub from "../../../../content/assets/kas/brands/sgc-logo.png"
import Subaru from "../../../../content/assets/kas/brands/subaru-logo.svg"
import SupercheapAuto from "../../../../content/assets/kas/brands/supercheap-auto-logo.svg"
import SweatyBetty from "../../../../content/assets/kas/brands/sweaty-betty-logo.png"
import Tab from "../../../../content/assets/kas/brands/tab-logo.png"
import TacoBell from "../../../../content/assets/kas/brands/taco-bell-logo.png"
import TagHeuer from "../../../../content/assets/kas/brands/tag-heuer-logo.svg"
import Target from "../../../../content/assets/kas/brands/target-logo.svg"
import TempleWebster from "../../../../content/assets/kas/brands/temple-webster-logo.svg"
import Tesco from "../../../../content/assets/kas/brands/tesco-logo.svg"
import Tesla from "../../../../content/assets/kas/brands/tesla-logo.svg"
import TheNationalLottery from "../../../../content/assets/kas/brands/the-national-lottery-logo.svg"
import TheSun from "../../../../content/assets/kas/brands/the-sun-logo.svg"
import TipTop from "../../../../content/assets/kas/brands/tip-top-logo.svg"
import Titleist from "../../../../content/assets/kas/brands/titleist-logo.svg"
import TkMaxx from "../../../../content/assets/kas/brands/tk-maxx-logo.svg"
import Tmz from "../../../../content/assets/kas/brands/tmz-logo.svg"
import Tooheys from "../../../../content/assets/kas/brands/tooheys-logo.svg"
import Topshop from "../../../../content/assets/kas/brands/topshop-logo.svg"
import TreliseCooper from "../../../../content/assets/kas/brands/trelise-cooper-logo.png"
import Tvnz from "../../../../content/assets/kas/brands/tvnz-logo.svg"
import Ufc from "../../../../content/assets/kas/brands/ufc-logo.svg"
import UnderArmour from "../../../../content/assets/kas/brands/under-armour-logo.svg"
import Vb from "../../../../content/assets/kas/brands/vb-logo.svg"
import VeuveClicquot from "../../../../content/assets/kas/brands/veuve-clicquot-logo.png"
import Victa from "../../../../content/assets/kas/brands/victa-logo.png"
import VictoriasSecret from "../../../../content/assets/kas/brands/victorias-secret-logo.svg"
import VikingCruises from "../../../../content/assets/kas/brands/viking-cruises-logo.svg"
import VirginActive from "../../../../content/assets/kas/brands/virgin-active-logo.svg"
import Vw from "../../../../content/assets/kas/brands/vw-logo.png"
import WaitrosePartners from "../../../../content/assets/kas/brands/waitrose-and-partners-logo.svg"
import Walmart from "../../../../content/assets/kas/brands/walmart-logo.svg"
import Wbc from "../../../../content/assets/kas/brands/wbc-logo.png"
import Wetherspoon from "../../../../content/assets/kas/brands/wetherspoon-logo.svg"
import Whistles from "../../../../content/assets/kas/brands/whistles-logo.svg"
import Whitcoulls from "../../../../content/assets/kas/brands/whitcoulls-logo.png"
import WhSmith from "../../../../content/assets/kas/brands/whsmith-logo.svg"
import WildTurkey from "../../../../content/assets/kas/brands/wild-turkey-logo.svg"
import WiliamHill from "../../../../content/assets/kas/brands/william-hill-logo.svg"
import WomensWeeklyAu from "../../../../content/assets/kas/brands/womens-weekly-au-logo.svg"
import WomensWeeklyNz from "../../../../content/assets/kas/brands/womens-weekly-nz-logo.svg"
import WorldRugby from "../../../../content/assets/kas/brands/world-rugby-logo.svg"
import Wrangler from "../../../../content/assets/kas/brands/wrangler-logo.svg"

const BrandLogo = ({ brand }) => {
  let brandLogo = null

  switch (brand) {
    case "ace-hardware":
      brandLogo = {
        src: AceHardware,
        name: "Ace Hardware",
      }
      break
    case "adidas":
      brandLogo = {
        src: Adidas,
        name: "Adidas",
      }
      break
    case "afl":
      brandLogo = {
        src: Afl,
        name: "AFL",
      }
      break
    case "air-nz":
      brandLogo = {
        src: AirNz,
        name: "Air New Zealand",
      }
      break
    case "aldi":
      brandLogo = {
        src: Aldi,
        name: "Aldi",
      }
      break
    case "all-blacks":
      brandLogo = {
        src: AllBlacks,
        name: "All Blacks",
      }
      break
    case "amazon":
      brandLogo = {
        src: Amazon,
        name: "Amazon",
      }
      break
    case "animates":
      brandLogo = {
        src: Animates,
        name: "Animates",
      }
      break
    case "apple":
      brandLogo = {
        src: Apple,
        name: "Apple",
      }
      break
    case "argos":
      brandLogo = {
        src: Argos,
        name: "Argos",
      }
      break
    case "asos":
      brandLogo = {
        src: Asos,
        name: "ASOS",
      }
      break
    case "audi":
      brandLogo = {
        src: Audi,
        name: "Audi",
      }
      break
    case "autozone":
      brandLogo = {
        src: AutoZone,
        name: "AutoZone",
      }
      break
    case "avon":
      brandLogo = {
        src: Avon,
        name: "AVON",
      }
      break
    case "bannatyne":
      brandLogo = {
        src: Bannatyne,
        name: "Bannatyne",
      }
      break
    case "barnes-noble":
      brandLogo = {
        src: BarnesNoble,
        name: "Barnes & Noble",
      }
      break
    case "bbc":
      brandLogo = {
        src: Bbc,
        name: "BBC",
      }
      break
    case "bbc-sports":
      brandLogo = {
        src: BbcSport,
        name: "BBC Sports",
      }
      break
    case "bcf":
      brandLogo = {
        src: Bcf,
        name: "BCF",
      }
      break
    case "bed-bath-n-table":
      brandLogo = {
        src: BedBathTable,
        name: "Bed Bath N' Table",
      }
      break
    case "beefeater":
      brandLogo = {
        src: Beefeater,
        name: "Beefeater",
      }
      break
    case "betfair":
      brandLogo = {
        src: Betfair,
        name: "Betfair",
      }
      break
    case "better-homes-gardens":
      brandLogo = {
        src: BetterHomesGardens,
        name: "Better Homes and Gardens",
      }
      break
    case "betty-crocker":
      brandLogo = {
        src: BettyCrocker,
        name: "Betty Crocker",
      }
      break
    case "betway":
      brandLogo = {
        src: Betway,
        name: "Betway",
      }
      break
    case "big-bash-league":
      brandLogo = {
        src: BigBashLeague,
        name: "Big Bash League",
      }
      break
    case "birds-eye":
      brandLogo = {
        src: BirdsEye,
        name: "Birds Eye",
      }
      break
    case "black-gold":
      brandLogo = {
        src: BlackGold,
        name: "Black & Gold",
      }
      break
    case "blue-ribbon":
      brandLogo = {
        src: BlueRibbon,
        name: "Blue Ribbon",
      }
      break
    case "bluebet":
      brandLogo = {
        src: BlueBet,
        name: "BlueBet",
      }
      break
    case "bmw":
      brandLogo = {
        src: Bmw,
        name: "BMW",
      }
      break
    case "boots":
      brandLogo = {
        src: Boots,
        name: "Boots",
      }
      break
    case "b-q":
      brandLogo = {
        src: Bq,
        name: "B&Q",
      }
      break
    case "bras-things":
      brandLogo = {
        src: BrasThings,
        name: "Bras N Things",
      }
      break
    case "british-airways":
      brandLogo = {
        src: BritishAirways,
        name: "British Airways",
      }
      break
    case "brooks-brothers":
      brandLogo = {
        src: BrooksBrothers,
        name: "Brooks Brothers",
      }
      break
    case "buick":
      brandLogo = {
        src: Buick,
        name: "Buick",
      }
      break
    case "bunnings":
      brandLogo = {
        src: Bunnings,
        name: "Bunnings",
      }
      break
    case "burberry":
      brandLogo = {
        src: Burberry,
        name: "Burberry",
      }
      break
    case "callaway":
      brandLogo = {
        src: Callaway,
        name: "Callaway Golf",
      }
      break
    case "calvin-klein":
      brandLogo = {
        src: CalvinKlein,
        name: "Calvin Klein",
      }
      break
    case "camping-world":
      brandLogo = {
        src: CampingWorld,
        name: "Camping World",
      }
      break
    case "campingworld":
      brandLogo = {
        src: CampingWorld,
        name: "Camping World",
      }
      break
    case "carlton":
      brandLogo = {
        src: Carlton,
        name: "Carlton & United Breweries",
      }
      break
    case "cartier":
      brandLogo = {
        src: Cartier,
        name: "Cartier",
      }
      break
    case "casper":
      brandLogo = {
        src: Casper,
        name: "Casper",
      }
      break
    case "chanel":
      brandLogo = {
        src: Chanel,
        name: "Chanel",
      }
      break
    case "chemist-warehouse":
      brandLogo = {
        src: ChemistWarehouse,
        name: "Chemist Warehouse",
      }
      break
    case "chevrolet":
      brandLogo = {
        src: Chevrolet,
        name: "Chevrolet",
      }
      break
    case "chivas-regal":
      brandLogo = {
        src: ChivasRegal,
        name: "Chivas Regal",
      }
      break
    case "citta":
      brandLogo = {
        src: Citta,
        name: "Città Design",
      }
      break
    case "clinique":
      brandLogo = {
        src: Clinique,
        name: "Clinique",
      }
      break
    case "coles":
      brandLogo = {
        src: Coles,
        name: "Coles",
      }
      break
    case "coral":
      brandLogo = {
        src: Coral,
        name: "Coral",
      }
      break
    case "costco":
      brandLogo = {
        src: Costco,
        name: "Costco",
      }
      break
    case "cotton-on":
      brandLogo = {
        src: CottonOn,
        name: "Cotton On",
      }
      break
    case "countdown":
      brandLogo = {
        src: Countdown,
        name: "Countdown",
      }
      break
    case "daily-mirror":
      brandLogo = {
        src: DailyMirror,
        name: "Daily Mirror",
      }
      break
    case "david-jones":
      brandLogo = {
        src: DavidJones,
        name: "David Jones",
      }
      break
    case "dewalt":
      brandLogo = {
        src: Dewalt,
        name: "DEWALT",
      }
      break
    case "dish-network":
      brandLogo = {
        src: DishNetwork,
        name: "Dish Network",
      }
      break
    case "dymocks":
      brandLogo = {
        src: Dymocks,
        name: "Dymocks",
      }
      break
    case "enews":
      brandLogo = {
        src: Enews,
        name: "E! News",
      }
      break
    case "estee-lauder":
      brandLogo = {
        src: EsteeLauder,
        name: "Estée Lauder",
      }
      break
    case "equinox":
      brandLogo = {
        src: Equinox,
        name: "Equinox",
      }
      break
    case "espn":
      brandLogo = {
        src: Espn,
        name: "ESPN",
      }
      break
    case "etsy":
      brandLogo = {
        src: Etsy,
        name: "Etsy",
      }
      break
    case "f1":
      brandLogo = {
        src: F1,
        name: "F1",
      }
      break
    case "family-circle":
      brandLogo = {
        src: FamilyCircle,
        name: "Family Circle",
      }
      break
    case "fanduel":
      brandLogo = {
        src: Fanduel,
        name: "FanDuel",
      }
      break
    case "fitbit":
      brandLogo = {
        src: Fitbit,
        name: "Fitbit",
      }
      break
    case "flight-centre":
      brandLogo = {
        src: FlightCentre,
        name: "Flight Centre",
      }
      break
    case "foodnetwork":
      brandLogo = {
        src: FoodNetwork,
        name: "Food Network",
      }
      break
    case "food-network":
      brandLogo = {
        src: FoodNetwork,
        name: "Food Network",
      }
      break
    case "foodstuffs":
      brandLogo = {
        src: Foodstuffs,
        name: "Foodstuffs",
      }
      break
    case "ford":
      brandLogo = {
        src: Ford,
        name: "Ford",
      }
      break
    case "forever-21":
      brandLogo = {
        src: Forever21,
        name: "Forever 21",
      }
      break
    case "fortnum-mason":
      brandLogo = {
        src: FortnumMason,
        name: "Fortnum & Mason",
      }
      break
    case "fox-news":
      brandLogo = {
        src: FoxNews,
        name: "Fox News",
      }
      break
    case "fox-sports":
      brandLogo = {
        src: FoxSports,
        name: "Fox Sports",
      }
      break
    case "foxtel":
      brandLogo = {
        src: Foxtel,
        name: "Foxtel",
      }
      break
    case "frankie-bennys":
      brandLogo = {
        src: FrankieBennys,
        name: "Frankie & Benny's",
      }
      break
    case "gardena":
      brandLogo = {
        src: Gardena,
        name: "GARDENA",
      }
      break
    case "garmin":
      brandLogo = {
        src: Garmin,
        name: "Garmin",
      }
      break
    case "glenfiddich":
      brandLogo = {
        src: Glenfiddich,
        name: "Glenfiddich",
      }
      break
    case "gmtv":
      brandLogo = {
        src: Gmtv,
        name: "GMTV",
      }
      break
    case "golfwarehouse":
      brandLogo = {
        src: GolfWarehouse,
        name: "Golf Warehouse",
      }
      break
    case "golf-warehouse":
      brandLogo = {
        src: GolfWarehouse,
        name: "Golf Warehouse",
      }
      break
    case "golfworld":
      brandLogo = {
        src: GolfWorld,
        name: "Golf World",
      }
      break
    case "golf-world":
      brandLogo = {
        src: GolfWorld,
        name: "Golf World",
      }
      break
    case "good-as-gold":
      brandLogo = {
        src: GoodAsGold,
        name: "Good As Gold",
      }
      break
    case "greggs":
      brandLogo = {
        src: Greggs,
        name: "Greggs",
      }
      break
    case "guzman-gomez":
      brandLogo = {
        src: GuzmanGomez,
        name: "Guzman y Gomez",
      }
      break
    case "halfords":
      brandLogo = {
        src: Halfords,
        name: "Halfords",
      }
      break
    case "harris-scarfe":
      brandLogo = {
        src: HarrisScarfe,
        name: "Harris Scarfe",
      }
      break
    case "hermes":
      brandLogo = {
        src: Hermes,
        name: "Hermès",
      }
      break
    case "holden":
      brandLogo = {
        src: Holden,
        name: "Holden",
      }
      break
    case "home-depot":
      brandLogo = {
        src: HomeDepot,
        name: "The Home Depot",
      }
      break
    case "home-shopping-network":
      brandLogo = {
        src: Hsn,
        name: "HSN",
      }
      break
    case "homebase":
      brandLogo = {
        src: Homebase,
        name: "Homebase",
      }
      break
    case "hunting-fishing":
      brandLogo = {
        src: HuntingFishing,
        name: "Hunting & Fishing New Zealand",
      }
      break
    case "hyundai":
      brandLogo = {
        src: Hyundai,
        name: "Hyundai",
      }
      break
    case "iceland":
      brandLogo = {
        src: Iceland,
        name: "Iceland",
      }
      break
    case "ikea":
      brandLogo = {
        src: Ikea,
        name: "IKEA",
      }
      break
    case "jb-hi-fi":
      brandLogo = {
        src: JbHiFi,
        name: "JB Hi-Fi",
      }
      break
    case "jc-penney":
      brandLogo = {
        src: JcPenney,
        name: "JCPenney",
      }
      break
    case "jcpenney":
      brandLogo = {
        src: JcPenney,
        name: "JCPenney",
      }
      break
    case "jd-sports":
      brandLogo = {
        src: JdSports,
        name: "JD Sports",
      }
      break
    case "jetstar":
      brandLogo = {
        src: Jetstar,
        name: "Jetstar",
      }
      break
    case "john-lewis":
      brandLogo = {
        src: JohnLewis,
        name: "John Lewis & Partners",
      }
      break
    case "karen-walker":
      brandLogo = {
        src: KarenWalker,
        name: "Karen Walker",
      }
      break
    case "kayo":
      brandLogo = {
        src: Kayo,
        name: "Kayo",
      }
      break
    case "kia":
      brandLogo = {
        src: Kia,
        name: "Kia",
      }
      break
    case "kmart":
      brandLogo = {
        src: Kmart,
        name: "Kmart",
      }
      break
    case "koala":
      brandLogo = {
        src: Koala,
        name: "Koala",
      }
      break
    case "ladbrokes":
      brandLogo = {
        src: Ladbrokes,
        name: "Ladbrokes",
      }
      break
    case "lexus":
      brandLogo = {
        src: Lexus,
        name: "Lexus",
      }
      break
    case "lotto-nz":
      brandLogo = {
        src: LottoNz,
        name: "Lotto NZ",
      }
      break
    case "lv":
      brandLogo = {
        src: LouisVuitton,
        name: "Louis Vuitton",
      }
      break
    case "louis-vuitton":
      brandLogo = {
        src: LouisVuitton,
        name: "Louis Vuitton",
      }
      break
    case "lululemon":
      brandLogo = {
        src: Lululemon,
        name: "lululemon",
      }
      break
    case "mac":
      brandLogo = {
        src: Mac,
        name: "Mac Cosmetics",
      }
      break
    case "mac-cosmetics":
      brandLogo = {
        src: Mac,
        name: "Mac Cosmetics",
      }
      break
    case "macys":
      brandLogo = {
        src: Macys,
        name: "Macy's",
      }
      break
    case "mamma-mia":
      brandLogo = {
        src: Mamamia,
        name: "Mamamia",
      }
      break
    case "marley-spoon":
      brandLogo = {
        src: MarleySpoon,
        name: "Marley Spoon",
      }
      break
    case "masterchef":
      brandLogo = {
        src: Masterchef,
        name: "MasterChef",
      }
      break
    case "m-s":
      brandLogo = {
        src: Ms,
        name: "M&S",
      }
      break
    case "mccain":
      brandLogo = {
        src: McCain,
        name: "McCain",
      }
      break
    case "mcdonalds":
      brandLogo = {
        src: McDonalds,
        name: "McDonald's",
      }
      break
    case "mecca":
      brandLogo = {
        src: Mecca,
        name: "MECCA",
      }
      break
    case "megamillions":
      brandLogo = {
        src: MegaMillions,
        name: "Mega Millions",
      }
      break
    case "mega-millions":
      brandLogo = {
        src: MegaMillions,
        name: "Mega Millions",
      }
      break
    case "mercedes-benz":
      brandLogo = {
        src: MercedesBenz,
        name: "Mercedes-Benz",
      }
      break
    case "miller-lite":
      brandLogo = {
        src: MillerLite,
        name: "Miller Lite",
      }
      break
    case "mini":
      brandLogo = {
        src: Mini,
        name: "MINI",
      }
      break
    case "mj-bale":
      brandLogo = {
        src: MjBale,
        name: "M.J. Bale",
      }
      break
    case "mlb":
      brandLogo = {
        src: Mlb,
        name: "MLB",
      }
      break
    case "morrisons":
      brandLogo = {
        src: Morrisons,
        name: "Morrisons",
      }
      break
    case "moto-g-power":
      brandLogo = {
        src: MotoG,
        name: "Moto G Power",
      }
      break
    case "motorola":
      brandLogo = {
        src: Motorola,
        name: "Motorola",
      }
      break
    case "neiman-marcus":
      brandLogo = {
        src: NeimanMarcus,
        name: "Neiman Marcus",
      }
      break
    case "netflix":
      brandLogo = {
        src: Netflix,
        name: "Netflix",
      }
      break
    case "next":
      brandLogo = {
        src: Next,
        name: "Next",
      }
      break
    case "nfl":
      brandLogo = {
        src: Nfl,
        name: "NFL",
      }
      break
    case "nike":
      brandLogo = {
        src: Nike,
        name: "Nike",
      }
      break
    case "noni-b":
      brandLogo = {
        src: NoniB,
        name: "Noni B",
      }
      break
    case "nrl":
      brandLogo = {
        src: Nrl,
        name: "NRL",
      }
      break
    case "oil-of-olay":
      brandLogo = {
        src: OilOfOlay,
        name: "Oil Of Olay",
      }
      break
    case "oreo":
      brandLogo = {
        src: Oreo,
        name: "Oreo",
      }
      break
    case "oz-lotteries":
      brandLogo = {
        src: OzLotteries,
        name: "Oz Lotteries",
      }
      break
    case "paknsave":
      brandLogo = {
        src: PakNSave,
        name: "PAK'nSAVE",
      }
      break
    case "park-hyatt":
      brandLogo = {
        src: ParkHyatt,
        name: "Park Hyatt",
      }
      break
    case "paul-smith":
      brandLogo = {
        src: PaulSmith,
        name: "Paul Smith",
      }
      break
    case "penfolds":
      brandLogo = {
        src: Penfolds,
        name: "Penfolds",
      }
      break
    case "petbarn":
      brandLogo = {
        src: Petbarn,
        name: "Petbarn",
      }
      break
    case "petco":
      brandLogo = {
        src: Petco,
        name: "Petco",
      }
      break
    case "popsugar":
      brandLogo = {
        src: Popsugar,
        name: "POPSUGAR",
      }
      break
    case "porsche":
      brandLogo = {
        src: Porsche,
        name: "Porsche",
      }
      break
    case "premier-league":
      brandLogo = {
        src: PremierLeague,
        name: "Premier League",
      }
      break
    case "primark":
      brandLogo = {
        src: Primark,
        name: "Primark",
      }
      break
    case "qantas":
      brandLogo = {
        src: Qantas,
        name: "Qantas",
      }
      break
    case "ralph-lauren":
      brandLogo = {
        src: RalphLauren,
        name: "Ralph Lauren",
      }
      break
    case "ray-ban":
      brandLogo = {
        src: RayBan,
        name: "Ray-Ban",
      }
      break
    case "readers-digest":
      brandLogo = {
        src: ReadersDigest,
        name: "Reader's Digest",
      }
      break
    case "red-baron":
      brandLogo = {
        src: RedBaron,
        name: "Red Baron",
      }
      break
    case "red-barron":
      brandLogo = {
        src: RedBaron,
        name: "Red Baron",
      }
      break
    case "rebel-sport":
      brandLogo = {
        src: RebelSport,
        name: "Rebel Sport",
      }
      break
    case "renault":
      brandLogo = {
        src: Renault,
        name: "Renault",
      }
      break
    case "ritz-carlton":
      brandLogo = {
        src: RitzCarlton,
        name: "The Ritz-Carlton",
      }
      break
    case "rolex":
      brandLogo = {
        src: Rolex,
        name: "Rolex",
      }
      break
    case "rsl":
      brandLogo = {
        src: Rsl,
        name: "RSL Australia",
      }
      break
    case "rugby-union":
      brandLogo = {
        src: WorldRugby,
        name: "Rugby Union",
      }
      break
    case "ryobi":
      brandLogo = {
        src: Ryobi,
        name: "Ryobi",
      }
      break
    case "samsung":
      brandLogo = {
        src: Samsung,
        name: "Samsung",
      }
      break
    case "seat":
      brandLogo = {
        src: Seat,
        name: "SEAT",
      }
      break
    case "skytv":
      brandLogo = {
        src: SkyNz,
        name: "Sky TV",
      }
      break
    case "sky-tv":
      brandLogo = {
        src: SkyNz,
        name: "Sky TV",
      }
      break
    case "spark-sport":
      brandLogo = {
        src: SparkSport,
        name: "Spark Sport",
      }
      break
    case "spark-nz":
      brandLogo = {
        src: SparkNz,
        name: "Spark NZ",
      }
      break
    case "speights":
      brandLogo = {
        src: Speights,
        name: "Speight's",
      }
      break
    case "spotlight":
      brandLogo = {
        src: Spotlight,
        name: "Spotlight",
      }
      break
    case "stanley":
      brandLogo = {
        src: Stanley,
        name: "Stanley",
      }
      break
    case "steinlager":
      brandLogo = {
        src: Steinlager,
        name: "Steinlager",
      }
      break
    case "stihl":
      brandLogo = {
        src: Stihl,
        name: "STIHL",
      }
      break
    case "stolen-girlfriends-club":
      brandLogo = {
        src: StolenGirlfriendsClub,
        name: "Stolen Girlfriends Club",
      }
      break
    case "subaru":
      brandLogo = {
        src: Subaru,
        name: "Subaru",
      }
      break
    case "supercheap-auto":
      brandLogo = {
        src: SupercheapAuto,
        name: "Supercheap Auto",
      }
      break
    case "sweaty-betty":
      brandLogo = {
        src: SweatyBetty,
        name: "Sweaty Betty",
      }
      break
    case "tab":
      brandLogo = {
        src: Tab,
        name: "TAB",
      }
      break
    case "taco-bell":
      brandLogo = {
        src: TacoBell,
        name: "Taco Bell",
      }
      break
    case "tag-heuer":
      brandLogo = {
        src: TagHeuer,
        name: "TAG Heuer",
      }
      break
    case "target":
      brandLogo = {
        src: Target,
        name: "Target",
      }
      break
    case "temple-webster":
      brandLogo = {
        src: TempleWebster,
        name: "Temple & Webster",
      }
      break
    case "tesco":
      brandLogo = {
        src: Tesco,
        name: "Tesco",
      }
      break
    case "tesla":
      brandLogo = {
        src: Tesla,
        name: "Tesla",
      }
      break
    case "the-national-lottery":
      brandLogo = {
        src: TheNationalLottery,
        name: "The National Lottery",
      }
      break
    case "the-sun":
      brandLogo = {
        src: TheSun,
        name: "The Sun",
      }
      break
    case "tip-top":
      brandLogo = {
        src: TipTop,
        name: "Tip Top",
      }
      break
    case "titleist":
      brandLogo = {
        src: Titleist,
        name: "Titleist",
      }
      break
    case "tk-maxx":
      brandLogo = {
        src: TkMaxx,
        name: "TK Maxx",
      }
      break
    case "tmz":
      brandLogo = {
        src: Tmz,
        name: "TMZ",
      }
      break
    case "tooheys":
      brandLogo = {
        src: Tooheys,
        name: "Tooheys",
      }
      break
    case "topshop":
      brandLogo = {
        src: Topshop,
        name: "Topshop",
      }
      break
    case "top-shop":
      brandLogo = {
        src: Topshop,
        name: "Topshop",
      }
      break
    case "trelise-cooper":
      brandLogo = {
        src: TreliseCooper,
        name: "Trelise Cooper",
      }
      break
    case "tvnz":
      brandLogo = {
        src: Tvnz,
        name: "TVNZ",
      }
      break
    case "ufc":
      brandLogo = {
        src: Ufc,
        name: "UFC",
      }
      break
    case "under-armour":
      brandLogo = {
        src: UnderArmour,
        name: "Under Armour",
      }
      break
    case "vb":
      brandLogo = {
        src: Vb,
        name: "Victoria Bitter",
      }
      break
    case "victa":
      brandLogo = {
        src: Victa,
        name: "Victa",
      }
      break
    case "victorias-secret":
      brandLogo = {
        src: VictoriasSecret,
        name: "Victoria's Secret",
      }
      break
    case "viking-cruises":
      brandLogo = {
        src: VikingCruises,
        name: "Viking Cruises",
      }
      break
    case "virgin-active":
      brandLogo = {
        src: VirginActive,
        name: "Virgin Active",
      }
      break
    case "sass-bide":
      brandLogo = {
        src: SassBide,
        name: "Sass & Bide",
      }
      break
    case "veuve-clicqout":
      brandLogo = {
        src: VeuveClicquot,
        name: "Veuve Clicqout",
      }
      break
    case "vw":
      brandLogo = {
        src: Vw,
        name: "Volkswagen",
      }
      break
    case "volkswagen":
      brandLogo = {
        src: Vw,
        name: "Volkswagen",
      }
      break
    case "waitrose":
      brandLogo = {
        src: WaitrosePartners,
        name: "Waitrose & Partners",
      }
      break
    case "walmart":
      brandLogo = {
        src: Walmart,
        name: "Walmart",
      }
      break
    case "wbc-boxing":
      brandLogo = {
        src: Wbc,
        name: "World Boxing Council",
      }
      break
    case "wetherspoon":
      brandLogo = {
        src: Wetherspoon,
        name: "Wetherspoons",
      }
      break
    case "wetherspoons":
      brandLogo = {
        src: Wetherspoon,
        name: "Wetherspoons",
      }
      break
    case "whistles":
      brandLogo = {
        src: Whistles,
        name: "Whistles",
      }
      break
    case "whitcoulls":
      brandLogo = {
        src: Whitcoulls,
        name: "Whitcoulls",
      }
      break
    case "wh-smith":
      brandLogo = {
        src: WhSmith,
        name: "WHSmith",
      }
      break
    case "whsmith":
      brandLogo = {
        src: WhSmith,
        name: "WHSmith",
      }
      break
    case "wild-turkey":
      brandLogo = {
        src: WildTurkey,
        name: "Wild Turkey",
      }
      break
    case "william-hill":
      brandLogo = {
        src: WiliamHill,
        name: "William Hill",
      }
      break
    case "womens-weekly-au":
      brandLogo = {
        src: WomensWeeklyAu,
        name: "Women's Weekly",
      }
      break
    case "womens-weekly-nz":
      brandLogo = {
        src: WomensWeeklyNz,
        name: "Women's Weekly",
      }
      break
    case "wrangler":
      brandLogo = {
        src: Wrangler,
        name: "Wrangler",
      }
      break
    default:
      console.log("-- missing brand ", brand)
      brandLogo = null
  }

  if (!brandLogo) {
    return null
  }

  return (
    <BrandItem>
      {brandLogo.src ? (
        <img
          src={brandLogo.src}
          alt={brandLogo.name || ""}
          title={brandLogo.name || ""}
          width="200"
        />
      ) : (
        <span>{brandLogo.name}</span>
      )}
    </BrandItem>
  )
}

const KasBrands = ({ brands, countryCode }) => {
  const geoProfileBrands = getPropertyValue(brands, countryCode)

  if (!geoProfileBrands.length) {
    return null
  }

  return (
    <KasBrandsWrapper>
      <span>Brands you may like:</span>
      <KasBrandsLogos>
        {geoProfileBrands.map((brandSlug, i) => (
          <BrandLogo key={i} brand={brandSlug} />
        ))}
      </KasBrandsLogos>
    </KasBrandsWrapper>
  )
}

export default KasBrands
