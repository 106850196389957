import React, { useState } from "react"
import styled from "styled-components"
import arrow from "images/Arrow.svg"
import arrowWhite from "images/ArrowWhite.svg"

const ButtonStyled = styled.button`
  background-color: ${({ variant }) =>
    variant === "dark" ? "#474747" : "rgb(239,239,239)"};
  color: ${({ variant }) => (variant === "dark" ? "white" : "black")};
  height: 45px;
  width: 45px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  font-size: 20px;
  position: relative;
  padding: 0;
  overflow: hidden;
  transition: border 0.3s ease;
  margin-right: 0;
  cursor: ${({ isDisabled }) => (isDisabled ? "no-drop" : "pointer")};
  &.active {
    border: 1px solid rgba(0, 0, 0, 0);
  }
  &:focus {
    outline: none;
  }
`

const ButtonInner = styled.div`
  height: 44px;
  width: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0;
  pointer-events: none;

  img {
    width: 16px;
    height: 16px;
    margin: 0;
    position: absolute;
    top: calc(50% - 8px);
    left: calc(50% - 8px);
    pointer-events: none;
  }

  img.rotate {
    transform: rotate(180deg);
  }
  img.disabled {
    opacity: 0.1;
    cursor: no-drop;
    &.white {
      opacity: 0.3;
    }
  }
`

const ButtonContent = styled.div`
  background-color: ${({ variant }) =>
    variant === "dark" ? "black" : "white"};
  height: 45px;
  width: 45px;
  transform: scaleX(1) scaleY(1) translateY(0px) translateX(0px);
  transition-property: all;
  transition-duration: 200ms;
  transition-timing-function: linear;
  will-change: transform;
  padding: 0;
  opacity: 1;
  box-sizing: border-box;
  position: relative;
  pointer-events: none;
  &.active {
    transform: scaleX(0.8) scaleY(0.8) translateY(-1px) translateX(-1px);
  }
  &.fade {
    opacity: 0;
  }
`

const NavButton = ({ link, variant, rotate, disabled, dirFunc }) => {
  const [hovered, setHovered] = useState(false)
  // const [clicked, setClicked] = useState(false)
  const [mouseDown, setMouseDown] = useState(false)

  const handleMouseEnter = () => {
    setHovered(true)
  }

  const handleMouseLeave = () => {
    setHovered(false)
    setMouseDown(false)
  }

  const handleMouseUp = (e, link) => {
    setMouseDown(false)
    setTimeout(() => {
      dirFunc()
    }, 200)
  }

  const handleMouseDown = () => {
    setMouseDown(true)
  }

  let buttonStyledClass = ""
  hovered && (buttonStyledClass += "active")

  let buttonInnerClass = ""
  hovered && (buttonInnerClass += "active")

  let buttonContentClass = ""
  hovered && (buttonContentClass += "active")
  mouseDown && (buttonContentClass += " fade")

  let arrowClass = "img "
  rotate && (arrowClass += "rotate ")
  disabled && (arrowClass += "disabled")
  variant === "dark" && (arrowClass += " white")

  return (
    <ButtonStyled
      className={buttonStyledClass}
      variant={variant}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseDown={handleMouseDown}
      onMouseUp={e => handleMouseUp(e, link)}
      isDisabled={disabled}
    >
      <ButtonInner className={buttonInnerClass} variant={variant}>
        <ButtonContent
          className={buttonContentClass}
          variant={variant}
        ></ButtonContent>
        <img
          src={variant === "dark" ? arrowWhite : arrow}
          alt="nav-arrow"
          className={arrowClass}
        />
      </ButtonInner>
    </ButtonStyled>
  )
}

export default NavButton
