import React from "react"

import Kas01 from "../../../../content/assets/kas/01-rising-stars.jpg"
import Kas02 from "../../../../content/assets/kas/02-beautiful-beginnings.jpg"
import Kas03 from "../../../../content/assets/kas/03-part-time-casuals.jpg"
import Kas04 from "../../../../content/assets/kas/04-young-ambition.jpg"
import Kas05 from "../../../../content/assets/kas/05-starting-out.jpg"
import Kas06 from "../../../../content/assets/kas/06-cash-strapped.jpg"
import Kas07 from "../../../../content/assets/kas/07-income-and-assets.jpg"
import Kas08 from "../../../../content/assets/kas/08-metro-mums.jpg"
import Kas09 from "../../../../content/assets/kas/09-humble-homes.jpg"
import Kas10 from "../../../../content/assets/kas/10-smart-money.jpg"
import Kas11 from "../../../../content/assets/kas/11-giving-it-a-go.jpg"
import Kas12 from "../../../../content/assets/kas/12-modest-means.jpg"
import Kas13 from "../../../../content/assets/kas/13-wealthy-and-wise.jpg"
import Kas14 from "../../../../content/assets/kas/14-homes-and-health.jpg"
import Kas15 from "../../../../content/assets/kas/15-frugal-homes.jpg"
import Kas16 from "../../../../content/assets/kas/16-comfortably-cruising.jpg"
import Kas17 from "../../../../content/assets/kas/17-garages-and-gardens.jpg"
import Kas18 from "../../../../content/assets/kas/18-on-the-tools.jpg"
import Kas19 from "../../../../content/assets/kas/19-champagne-seniors.jpg"
import Kas20 from "../../../../content/assets/kas/20-prudent-pensions.jpg"
import Kas21 from "../../../../content/assets/kas/21-grandkids-and-causes.jpg"
import Kas22 from "../../../../content/assets/kas/22-silver-foxes.jpg"
import Kas23 from "../../../../content/assets/kas/23-lawns-and-lotteries.jpg"
import Kas24 from "../../../../content/assets/kas/24-thrifty-retirees.jpg"

const getImageSrc = code => {
  let src = null

  switch (code) {
    case "01":
      src = Kas01
      break
    case "02":
      src = Kas02
      break
    case "03":
      src = Kas03
      break
    case "04":
      src = Kas04
      break
    case "05":
      src = Kas05
      break
    case "06":
      src = Kas06
      break
    case "07":
      src = Kas07
      break
    case "08":
      src = Kas08
      break
    case "09":
      src = Kas09
      break
    case "10":
      src = Kas10
      break
    case "11":
      src = Kas11
      break
    case "12":
      src = Kas12
      break
    case "13":
      src = Kas13
      break
    case "14":
      src = Kas14
      break
    case "15":
      src = Kas15
      break
    case "16":
      src = Kas16
      break
    case "17":
      src = Kas17
      break
    case "18":
      src = Kas18
      break
    case "19":
      src = Kas19
      break
    case "20":
      src = Kas20
      break
    case "21":
      src = Kas21
      break
    case "22":
      src = Kas22
      break
    case "23":
      src = Kas23
      break
    case "24":
      src = Kas24
      break
    default:
      src = null
  }

  return src
}

const KasProfileImage = ({ code, altText }) => {
  const imageSrc = getImageSrc(code)
  return <div>{imageSrc && <img src={imageSrc} alt={altText || ""} />}</div>
}

export default KasProfileImage
