import React from "react"
import styled from "styled-components"
import InfoIcon from "../../../../content/assets/kas/icons/icon-info.svg"
import device from "utils/media"

const StyledWhatIsKasButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  text-align: right;
  color: black;
  padding-left: 14px;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.7;
  }
  @media ${device.md} {
    margin-bottom: 30px;
  }
  img {
    margin-left: 10px;
  }
`

const WhatIsKasButton = ({ handleInfoModal }) => {
  return (
    <StyledWhatIsKasButton role="button" onClick={handleInfoModal}>
      <div className="hidden-until-sm">What is KAS?</div>{" "}
      <img src={InfoIcon} alt="What is KAS?" />
    </StyledWhatIsKasButton>
  )
}

export default WhatIsKasButton
