import BannerImg from "../../../../content/assets/kas-demo-hero.png"
import { kasProfiles } from "./KasProfiles"

const kasDemoPageData = {
  hero_banner: {
    heading: `What's My KAS Segment?`,
    background_colour: "#7be7e3",
    text_colour: "#000",
    subheading: `<p>KAS allocates consumers into one of 24 distinct&nbsp;cohorts. <br>Find out which cohort you belong to and a description of a typical member of your&nbsp;group.</p>`,
    include_image_slider: true,
    slider_images: [
      {
        url: BannerImg,
        alt: "What's My KAS Segment?",
      },
    ],
  },
  kasProfiles: kasProfiles
}

export { kasDemoPageData }
