import React from "react"
import { PreHeading } from "components/HeroBanner"
import KasMetric from "components/Kas/KasMetric"
import KasProfileImage from "components/Kas/KasProfileImage"
import KasTraits from "components/Kas/KasTraits"
import KasBrands from "components/Kas/KasBrands"
import { ButtonStyled } from "components/FormInputs"
import WhatIsKasButton from "components/Kas/WhatIsKasButton"
import {
  KasProfileImageWrapper,
  StyledKasTitle,
  SegmentDetails,
  StyledCorner,
} from "./KasProfile.styled"

const KasProfile = ({
  profile,
  isResult,
  countryCode,
  handleNewSearch,
  handleInfoModal,
}) => {
  return (
    <div>
      {isResult && (
        <StyledKasTitle>
          <h2>Your result: </h2>
          <WhatIsKasButton handleInfoModal={handleInfoModal} />
        </StyledKasTitle>
      )}
      <div className="row">
        <div className="col md-4 lg-5">
          <KasProfileImageWrapper isResult={isResult}>
            <div className="styled-image">
              {profile.code && (
                <KasProfileImage
                  code={profile.code}
                  altText={profile.name || ""}
                />
              )}
            </div>
            {isResult && (
              <KasBrands countryCode={countryCode} brands={profile.brands} />
            )}
          </KasProfileImageWrapper>
        </div>
        <div className="col md-8 lg-7">
          <SegmentDetails isResult={isResult}>
            <PreHeading>Segment #{profile.code.replace(/^0+/, "")}</PreHeading>
            <h3 className="text-uppercase">{profile.name}</h3>
            <p className="lead">{profile.description}</p>
            <KasMetric metric={{ type: "age", value: profile.age }} />
            <KasMetric metric={{ type: "gender", value: profile.gender }} />
            <KasMetric metric={{ type: "income", value: profile.income }} />
            {profile.traits && <KasTraits traits={profile.traits} />}
            <StyledCorner className="hidden-until-md corner-top" />
            <StyledCorner className="hidden-until-md corner-bottom" flip="true" />
          </SegmentDetails>
          {isResult && (
            <ButtonStyled
              className="btn text-uppercase"
              onClick={handleNewSearch}
            >
              New search
            </ButtonStyled>
          )}
        </div>
      </div>
    </div>
  )
}

export default KasProfile
