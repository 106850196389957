import React, { useEffect, useState, useRef } from "react"
import SwiperCore, { Navigation } from "swiper"
import Swiper from "react-id-swiper"
import Arrow from "images/Arrow.svg"
import View from "components/View"
import NavButton from "components/Ui/Button/NavButton"
import KasProfile from "components/Kas/KasProfile"
import KasProfileImage from "components/Kas/KasProfileImage"
import KasModal from "components/Kas/KasModal"
import { SliderWrap, Heading } from "./KasCarousel.styled"

SwiperCore.use([Navigation])

const KasCarousel = ({ profiles, heading, slideSize, ...props }) => {
  // let slides = null
  const swiperRef = useRef(null)
  const [sliderPos, setSliderPos] = useState("start")
  const [openProfileModal, setOpenProfileModal] = useState(false)
  const [selectedProfile, setSelectedProfile] = useState(null)

  const sliderParams = {
    spaceBetween: 0,
    slidesPerView: "auto",
    loopAdditionalSlides: 0,
    navigation: {
      nextEl: "slider-next",
      prevEl: "slider-prev",
    },
    freeMode: true,
    init: true,
    loop: false,
    /// cssMode: true,
    centeredSlides: false,
    on: {
      init: swiper => {
        let sliderPosition
        if (swiper !== null) {
          sliderPosition =
            swiper.isEnd && swiper.isBeginning ? "disabled" : "start"

          setSliderPos(sliderPosition)
        }
      },
      realIndexChange: swiper => {
        let position
        if (swiper !== null) {
          if (swiper.isEnd) {
            position = "end"
          } else if (swiper.isBeginning) {
            position = "start"
          } else {
            position = false
          }

          setSliderPos(position)
        }
      },
    },
  }

  useEffect(() => {
    setSliderPos("start")
  }, [])

  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext()
    }
  }

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev()
    }
  }

  const toggleProfileModal = () => {
    setOpenProfileModal(!openProfileModal)
  }

  const handleSlideClick = code => {
    const profile = profiles.find(profile => profile.code === code)
    setSelectedProfile(profile)
    toggleProfileModal()
  }

  return (
    <>
      <View>
        <Heading className={`heading`}>
          <h2>{heading}</h2>
          <div
            className={`slider-nav ${
              profiles.length <= 4 && "slider-nav--short"
            }`}
          >
            <NavButton
              className="slider-prev"
              variant="dark"
              rotate
              dirFunc={goPrev}
              direction="prev"
              disabled={sliderPos === "disabled" || sliderPos === "start"}
            >
              <img src={Arrow} alt="Previous item" />
            </NavButton>
            <NavButton
              className="slider-next"
              variant="dark"
              dirFunc={goNext}
              disabled={sliderPos === "disabled" ? true : sliderPos === "end"}
            >
              <img src={Arrow} alt="Next item" />
            </NavButton>
          </div>
        </Heading>

        {profiles && (
          <>
            <SliderWrap className={`${slideSize || "small"}-slides`}>
              <Swiper ref={swiperRef} {...sliderParams}>
                {/*getSlides(profiles)*/}
                {profiles.map((profile, i) => (
                  <div key={i} className="col sm-6 md-4 xl-3">
                    <div
                      className="kas-popup"
                      onClick={() => handleSlideClick(profile.code)}
                      onKeyDown={() => handleSlideClick(profile.code)}
                      role="button"
                      tabIndex={0}
                    >
                      <KasProfileImage
                        code={profile.code}
                        altText={profile.name}
                      />
                    </div>
                  </div>
                ))}
              </Swiper>
            </SliderWrap>
          </>
        )}
      </View>

      <KasModal isOpen={openProfileModal} onRequestClose={toggleProfileModal}>
        <KasProfile
          profile={selectedProfile}
          countryCode={null}
          isResult={false}
        />
      </KasModal>
    </>
  )
}

export default KasCarousel
