import React from "react"
import Tooltip from "components/Ui/Tooltip"
import { TraitsSection, TraitsCollection, TraitWrap } from "./KasTraits.styled"

import BeachIcon from "../../../../content/assets/kas/icons/traits/trait-beach.svg"
import BeerIcon from "../../../../content/assets/kas/icons/traits/trait-beer.svg"
import CampingIcon from "../../../../content/assets/kas/icons/traits/trait-camping.svg"
import CarIcon from "../../../../content/assets/kas/icons/traits/trait-cars.svg"
import ChampagneIcon from "../../../../content/assets/kas/icons/traits/trait-champagne.svg"
import CinemaIcon from "../../../../content/assets/kas/icons/traits/trait-cinema.svg"
import CocktailsIcon from "../../../../content/assets/kas/icons/traits/trait-cocktail.svg"
import CookAtHomeIcon from "../../../../content/assets/kas/icons/traits/trait-cooking-at-home.svg"
import CookingIcon from "../../../../content/assets/kas/icons/traits/trait-cooking.svg"
import CruiseIcon from "../../../../content/assets/kas/icons/traits/trait-cruise.svg"
import DiyIcon from "../../../../content/assets/kas/icons/traits/trait-diy.svg"
import FashionIcon from "../../../../content/assets/kas/icons/traits/trait-fashion.svg"
import FastFoodIcon from "../../../../content/assets/kas/icons/traits/trait-fast-food.svg"
import FineDiningIcon from "../../../../content/assets/kas/icons/traits/trait-fine-dining.svg"
import FoodDeliveryIcon from "../../../../content/assets/kas/icons/traits/trait-food-delivery.svg"
import GamblingIcon from "../../../../content/assets/kas/icons/traits/trait-gambling.svg"
import GardeningIcon from "../../../../content/assets/kas/icons/traits/trait-gardening.svg"
import GolfIcon from "../../../../content/assets/kas/icons/traits/trait-golf.svg"
import GraduateIcon from "../../../../content/assets/kas/icons/traits/trait-graduate.svg"
import GymIcon from "../../../../content/assets/kas/icons/traits/trait-gym.svg"
import HighSchoolIcon from "../../../../content/assets/kas/icons/traits/trait-high-school.svg"
import MoviesIcon from "../../../../content/assets/kas/icons/traits/trait-movies.svg"
import MusicIcon from "../../../../content/assets/kas/icons/traits/trait-music.svg"
import OverseasIcon from "../../../../content/assets/kas/icons/traits/trait-overseas.svg"
import PetsIcon from "../../../../content/assets/kas/icons/traits/trait-pets.svg"
import ProfessionalIcon from "../../../../content/assets/kas/icons/traits/trait-professional.svg"
import RestaurantsIcon from "../../../../content/assets/kas/icons/traits/trait-food-plate.svg"
import ShoppingIcon from "../../../../content/assets/kas/icons/traits/trait-shopping.svg"
import SportIcon from "../../../../content/assets/kas/icons/traits/trait-sport.svg"
import SportsCarIcon from "../../../../content/assets/kas/icons/traits/trait-sports-car.svg"
import StayAtHomeIcon from "../../../../content/assets/kas/icons/traits/trait-stay-at-home.svg"
import TakeOutIcon from "../../../../content/assets/kas/icons/traits/trait-take-out.svg"
import TheArtsIcon from "../../../../content/assets/kas/icons/traits/trait-the-arts.svg"
import TheatreIcon from "../../../../content/assets/kas/icons/traits/trait-theatre.svg"
import TradesIcon from "../../../../content/assets/kas/icons/traits/trait-trades.svg"
import TvIcon from "../../../../content/assets/kas/icons/traits/trait-tv.svg"
import WineIcon from "../../../../content/assets/kas/icons/traits/trait-wine.svg"

const getDetails = trait => {
  let details = null

  switch (trait.icon) {
    case "beach":
      details = {
        icon: BeachIcon,
        name: "Beach holiday",
      }
      break
    case "beer":
      details = {
        icon: BeerIcon,
        name: "Beer",
      }
      break
    case "camping":
      details = {
        icon: CampingIcon,
        name: "Camping",
      }
      break
    case "car-maintenance":
      details = {
        icon: CarIcon,
        name: "Car maintenance",
      }
      break
    case "champagne":
      details = {
        icon: ChampagneIcon,
        name: "Champagne",
      }
      break
    case "cinema":
      details = {
        icon: CinemaIcon,
        name: "Cinema",
      }
      break
    case "cocktails":
      details = {
        icon: CocktailsIcon,
        name: "Cocktails",
      }
      break
    case "cook-at-home":
      details = {
        icon: CookAtHomeIcon,
        name: "Cooking at home",
      }
      break
    case "cooking":
      details = {
        icon: CookingIcon,
        name: "Cooking",
      }
      break
    case "cruise":
      details = {
        icon: CruiseIcon,
        name: "Cruise holiday",
      }
      break
    case "diy":
      details = {
        icon: DiyIcon,
        name: "DIY",
      }
      break
    case "fashion":
      details = {
        icon: FashionIcon,
        name: "Fashion",
      }
      break
    case "fast-food":
      details = {
        icon: FastFoodIcon,
        name: "Fast food",
      }
      break
    case "fine-dining":
      details = {
        icon: FineDiningIcon,
        name: "Fine dining",
      }
      break
    case "food-delivery":
      details = {
        icon: FoodDeliveryIcon,
        name: "Food delivery",
      }
      break
    case "gambling":
      details = {
        icon: GamblingIcon,
        name: "Gambling",
      }
      break
    case "gardening":
      details = {
        icon: GardeningIcon,
        name: "Gardening",
      }
      break
    case "golf":
      details = {
        icon: GolfIcon,
        name: "Golf",
      }
      break
    case "graduate":
      details = {
        icon: GraduateIcon,
        name: "Graduate",
      }
      break
    case "gym":
      details = {
        icon: GymIcon,
        name: "Gym",
      }
      break
    case "high-school":
      details = {
        icon: HighSchoolIcon,
        name: "High school",
      }
      break
    case "movies":
      details = {
        icon: MoviesIcon,
        name: "Movies",
      }
      break
    case "music":
      details = {
        icon: MusicIcon,
        name: "Music",
      }
      break
    case "overseas":
      details = {
        icon: OverseasIcon,
        name: "Overseas holiday",
      }
      break
    case "pets":
      details = {
        icon: PetsIcon,
        name: "Pets",
      }
      break
    case "professional":
      details = {
        icon: ProfessionalIcon,
        name: "Professional",
      }
      break
    case "restaurants":
      details = {
        icon: RestaurantsIcon,
        name: "Restaurants",
      }
      break
    case "shopping":
      details = {
        icon: ShoppingIcon,
        name: "Shopping",
      }
      break
    case "sport":
      details = {
        icon: SportIcon,
        name: "Sport",
      }
      break
    case "sports-car":
      details = {
        icon: SportsCarIcon,
        name: "Sports car",
      }
      break
    case "stay-home":
      details = {
        icon: StayAtHomeIcon,
        name: "Stay at home",
      }
      break
    case "stay-at-home":
      details = {
        icon: StayAtHomeIcon,
        name: "Stay at home",
      }
      break
    case "take-out":
      details = {
        icon: TakeOutIcon,
        name: "Take out",
      }
      break
    case "the-arts":
      details = {
        icon: TheArtsIcon,
        name: "The arts",
      }
      break
    case "theatre":
      details = {
        icon: TheatreIcon,
        name: "Theatre",
      }
      break
    case "trades":
      details = {
        icon: TradesIcon,
        name: "Trades",
      }
      break
    case "tv":
      details = {
        icon: TvIcon,
        name: "TV",
      }
      break
    case "wine":
      details = {
        icon: WineIcon,
        name: "Wine",
      }
      break
    default:
      console.log("-- missing trait ", trait.icon)
      details = null
  }

  return details
}

const KasTraits = ({ traits }) => {
  return (
    <TraitsSection>
      <h4>Traits</h4>
      <TraitsCollection>
        {traits.map((trait, i) => {
          const details = getDetails(trait)
          if (details && details.name && details.icon) {
            return (
              <Tooltip key={i} label={details.name} direction="top">
                <TraitWrap className="trait-wrap">
                  <img src={details.icon} alt={details.name} width="40" />
                </TraitWrap>
              </Tooltip>
            )
          } else {
            return null
          }
        })}
      </TraitsCollection>
    </TraitsSection>
  )
}

export default KasTraits
